import React, { useState, useEffect } from 'react';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';

import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar'; 
import Footer from '../Layout/Footer';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { masterData } from '../../api/master-data';
import { putAPI,postAPI, uploadImageAPI,get, deleteAPI } from '../../services/ApiService';
import {DateFunc} from '../../services/Date';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Primary() {
    const [groups, setGroups] = useState();
    const [countries, setCountries] = useState();
    const [states, setStates] = useState();
    const [currencyCode, setCurrencyCode] = useState();
    const [employeesizes, setEmployeeSizes] = useState();
    const [revenuesizes, setRevenueSizes] = useState();
    const [industries, setIndustries] = useState();
    const [departments, setDepartments] = useState();
    const [designations, setDesignations] = useState();
    const [comstatuses, setComStatus] = useState();
    const [accessstatuses, setAccessStatus] = useState();
    const [respondentIndex, setRespondentIndex] = useState(0);
    const [respondent, setRespondent] = useState({});
    const [studyNames, setStudyNames] = useState([]);
    const [message, setMessage] = useState([]);
    const [popUpDisplay, setPopUpDisplay] = useState(false);
    const [appointmentDisplay, setAppointmentDisplay] = useState("none");
    const [appointmentTime, setappointmentTime] = useState(new Date());//datepicker start date

    
    // const [genders, setGenders] = useState();
    // const [agegroups, setAgeGroups] = useState(); 
    // const [educations, setEducations] = useState(); 
    // const [relationships, setRelationships] = useState(); 
     //const [incomegroups, setIncomeGroups] = useState(); 

    const validationSchema = Yup.object().shape({
        selectedSkills: Yup.array().min(1, 'Select at least one skill').required('Skills are required'),
    });


    useEffect(() => {
        document.title = "Research - GoToSample"   
        //if(dataFetchedRef.current){ // to stop double loading of APIs, skips in first mount
           
        
            const getCountries = async() => {
                let res = await masterData({'moduleName':'country'})
                if(res.status === 1){
                    setCountries(res.payload)
                }
            };
            //getCountries();
            const getEmployeeSizes = async() => {
                let res = await masterData({moduleName:'employee-size'})
                if(res.status === 1){
                    setEmployeeSizes(res.payload)
                }
            }
            //getEmployeeSizes();
            const getRevenueSizes = async() => {
                let res = await masterData({moduleName:'company-revenue'})
                if(res.status === 1){
                    setRevenueSizes(res.payload)
                }
            }
            //getRevenueSizes();
            const getDepartments = async() => {
                let res = await masterData({moduleName:'departments'})
                if(res.status === 1){
                    setDepartments(res.payload)
                }
            }
            //getDepartments()
            const getIndustries = async() => {
                let res = await masterData({moduleName:'industry'})
                if(res.status === 1){
                    setIndustries(res.payload)
                }
            }
            //getIndustries();
            const getDesignations = async() => {
                let res = await masterData({moduleName:'titles'})
                if(res.status === 1){
                    setDesignations(res.payload)
                }
            }
            //getDesignations();

            const getStudyNames = async() => {
                let res = await get('/research/study/list',{status:1,current:1}); 
                if(res.status === 1){
                    setStudyNames(res.payload)
                }
            }
            getStudyNames();

            const getComStatus = async() => {
                let res = await masterData({moduleName:'communication-status'})
                if(res.status === 1){
                    setComStatus(res.payload)
                }
            }
            getComStatus();

            const getAccessStatus = async() => {
                let res = await masterData({moduleName:'database-access-status'})
                if(res.status === 1){
                    setAccessStatus(res.payload)
                }
            }
            getAccessStatus();

        getFilters();
    },[]);
    
    const getStates = async(countryID) =>{
        setStates();

        let data = {countryID}
        let res = await get('/research/primary/states', data);        
            if(res.status === 1){
                setStates(res.payload.states.split("#"));
            }
    }
    
    const getFilters = async() =>{
        let data = {}
        let res = await get('/research/primary/filters', data);        
            if(res.status === 1){
                if(res.payload.countries != null)
                setCountries(res.payload.countries.split("#"));
                if(res.payload.groups != null)
                setGroups(res.payload.groups.split("#"));
                //setStates(res.payload.states);
                if(res.payload.revenues != null)
                    setRevenueSizes(res.payload.revenues.split("#"));
                 if(res.payload.employee_sizes != null)
                    setEmployeeSizes(res.payload.employee_sizes.split("#"));
                
                if(res.payload.departments != null)
                setDepartments(res.payload.departments.split("#"));

                if(res.payload.industries != null)
                setIndustries(res.payload.industries.split("#"));

                if(res.payload.job_titles != null)
                setDesignations(res.payload.job_titles.split("#"));
            }else{
            }
    }

    useEffect(() => {
        loadRespondents();
    },[respondentIndex]);


    const filterRespondent = async(e) => {
        // Handle form submission
        //console.log(values.selectedSkills);
        e.preventDefault();
        let preIndex = respondentIndex;
        setRespondentIndex(0);
        if(preIndex ==0){
            loadRespondents()
        }
    };

    async function  loadRespondents(){
        const formData = new FormData(document.getElementById('filterForm'));
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;           
        }
        formInput.offset = respondentIndex;
        formInput.records = 1;   
        let res = await get('/research/primary/respondent', formInput);        
            if(res.status === 1){
                setRespondent(res.payload[0]);
            }else{                
                setRespondent({});
            }
    }

    const movePrev = async(e)=>{
        if(respondentIndex > 0){
            setRespondentIndex(respondentIndex-1);
        }
    }
    const moveNext = async(e)=>{
        console.log(respondent?.totalCount)
        console.log(respondentIndex)

        if(respondent && respondent?.totalCount > 0 && respondentIndex < respondent?.totalCount-1 ){
            setRespondentIndex(respondentIndex+1);
        }        
    }

    const saveDisposition = async(e) => {
        // Handle form submission
        //console.log(values.selectedSkills);
        e.preventDefault();
        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;           
        }
        let opt = document.getElementById("comStatusID");
        formInput.comStatusTxt = opt.options[opt.selectedIndex].text;
        formInput.select = 1;
        let res = await postAPI('/research/primary/save-disposition', formInput);  
        setMessage(res.message)      
            if(res.status === 1){
                e.target.reset();
                moveNext()

            }else{
            }
       
    };

    
    // const setCurrency = (countryID)=>{
    //     let country = countries.filter(c=>{
    //         return c.id == countryID
    //     })[0];
    //     console.log(country);
    //     let currency = (JSON.parse(country.description)).currency;
    //     console.log(country);
    //     setCurrencyCode(currency);
    // }

// useEffect(() => {
//     const getIncomeGroups = async() => {
//         if(currencyCode && currencyCode != ""){
//             let res = await masterData({moduleName:'monthly-income-'+currencyCode.toLowerCase()})
//             if(res.status === 1){
//                 setIncomeGroups(res.payload)
//             }
//         }
//     }
//     getIncomeGroups();
// },[currencyCode]);


function prepareIframe(src){
   
//     var x = document.createElement("IFRAME");
//   x.setAttribute("src", "https://www.w3schools.com");
//   document.body.appendChild(x);

return "<iframe src='"+src+"' title='description'></iframe>"
}



    return (
        <>

            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                    {/* <div className='page-title p-4 pb-0'>
                        <h1 className='h5 m-0 text-uppercase'>Researche Database Access</h1>
                    </div> */}
                    <section className=''>
                        <div className='container'>
                            <div className='row g-3'>
                                
                                {/* left form start */}
                                <div className='col-lg-4'>
                                    <div className='form-theme included-label rounded-4 shadow'>
                                        <h2 className='h5 bg-light border-bottom border-primary border-4  p-2'> &nbsp; Choose Criteria</h2>
                                        {/* <Formik initialValues={{ selectedSkills: [] }} validationSchema={validationSchema} onSubmit={loadRespondents}> */}
                                        <form onSubmit={filterRespondent} className='p-3' id="filterForm">
                                            {/* <Form className='p-3'> */}
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className='row gy-1'>
                                                        <div className='col-12'>
                                                                <div className="form-group">
                                                                    {/* <select id="groupId" name='groupId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Group</option>
                                                                        {groups?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select>  */}

                                                                    {groups?.map((row,index) => {
                                                                        return (
                                                                            <>
                                                                                <input type="radio" name='groupId' id={"groupId"+row} value={row} selected=''  style={{cursor:"pointer"}} />
                                                                                 &nbsp;<label for={"groupId"+row} style={{cursor:"pointer",paddingRight:(index==(groups.length-1))?"0":"40px"}}>{row}</label>
                                                                        </>
                                                                        )
                                                                    })}


                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Country</label>
                                                                    {/* <Select
                                                                        isMulti
                                                                        name="Country"
                                                                        options={countries}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    /> */}
                                                                    <select id="countryId" name='countryId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1" onChange={(e)=>getStates(e.target.value)} >
                                                                        <option value={""}  selected>Select Country</option>
                                                                        {countries?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="Country"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">State</label>
                                                                    {/* <Select
                                                                        isMulti
                                                                        name="stateId"
                                                                        options={[]}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    /> */}
                                                                    <select id="stateId" name='stateId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select State</option>
                                                                        {states?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="State"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Industry</label>
                                                                    <select id="industryId" name='industryId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Industry</option>
                                                                        {industries?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="Industry"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Employee Size</label>
                                                                    <select id="employeeSizeId" name='employeeSizeId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Employee Size</option>
                                                                        {employeesizes?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="EmployeeSize"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Revenue</label>
                                                                    <select id="revenueId" name='revenueId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Revenue</option>
                                                                        {revenuesizes?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="Revenue"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Job Title</label>
                                                                    <select id="jobTitleId" name='jobTitleId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Job Title</option>
                                                                        {designations?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="JobTitle"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Department</label>
                                                                    <select id="departmentId" name='departmentId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Department</option>
                                                                        {departments?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select>
                                                                    {/* <ErrorMessage
                                                                        name="Department"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Data Access Status</label>
                                                                    {/* <Select
                                                                        isMulti
                                                                        name="Department"
                                                                        options={skillsOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    /> */}
                                                                    <select id="accessStatusId" name='accessStatusId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select last access status</option>
                                                                        {accessstatuses?.map((row) => {
                                                                                return (<option value={row.id} selected='' >{row.name}</option>)
                                                                            })}
                                                                    </select>
                                                                    {/* <ErrorMessage
                                                                        name="Department"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Communication Status</label>
                                                                    {/* <Select
                                                                        isMulti
                                                                        name="Department"
                                                                        options={skillsOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    /> */}
                                                                    <select id="communicationStatusId" name='communicationStatusId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select communication status</option>
                                                                        {comstatuses?.map((row) => {
                                                                                return (<option value={row.id} selected='' >{row.name}</option>)
                                                                            })}
                                                                    </select>
                                                                    {/* <ErrorMessage
                                                                        name="Department"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                            <button type="submit" className='w-100 my-3 btn btn-md rounded-2 btn-warning' >Submit</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-lg-6'>
                                                        <div className='row gy-1'>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary mt-3'>No. of Database</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary'>With Email</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary'>Mobile No.</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary'>Landline No.</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessWithEmail" className="form-label">Database Access with email</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" name='AccessWithEmail' placeholder="No. of Database Access with email" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessWithMobile" className="form-label">Database Access with Mobile Number</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" name='AccessWithMobile' placeholder="No. of Database Access with Mobile Number" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessWithContact" className="form-label">Database Access with Contact Number</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" name='AccessWithMobile' placeholder="No. of Database Access with Contact Number" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessTo" className="form-label">Access to</label>
                                                                    <select className="form-select" name="AccessTo" required>
                                                                        <option value="">--Select--</option>
                                                                        <option>HR</option>
                                                                        <option>IT</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className='col-12'>
                                                                <button type="submit" className='w-100 my-3 py-3 btn btn-md rounded-2 btn-warning'>Access</button>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            {/* </form> */}
                                        </form>
                                        {/* </Formik> */}
                                    </div>
                                </div>
                                {/* left form end */}
                                <div className='col-lg-8 data-display-table'>
                                    <div className='table-responsive'>
                                        <table className="table table-theme table-striped table-primary1">
                                            {/* <thead>
                                                <tr>
                                                    <th scope="col" width="100">Date</th>
                                                    <th scope="col" >PM Name</th>
                                                    

                                                </tr>
                                            </thead> */}
                                            <tbody>
                                                <tr>
                                                    <td  style={{padding:"0.35rem 0.6rem"}}><b>Respondent Name</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>{respondent?.D07}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>Company Name</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>{respondent?.D01}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>City</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>{respondent?.G03}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>Industry Name</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>{respondent?.D04A}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>Designation</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}> - {respondent?.D06A} <br/> - {respondent?.D06B}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem",width:"200px"}}><b>Last Data Access</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>{respondent?.lastAccess && <> {DateFunc.format(respondent?.lastAccess*1000,"dd mmm yyyy")}</>}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>Communication Status</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>{respondent?.comStatusTxt}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>Email</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>
                                                        {respondent?.E01 && <div className='mb-2'><div style={{width:"60%",display:"inline-block"}}>{respondent?.E01}</div> &nbsp; <a href={"mailto:"+respondent?.E01} className='btn btn-sm btn-danger'><i className='mdi mdi-email'></i></a><br/></div>}
                                                        {respondent?.E02 && <div className='mb-2'><div style={{width:"60%",display:"inline-block"}}>{respondent?.E02}</div> &nbsp; <a href={"mailto:"+respondent?.E02} className='btn btn-sm btn-danger'><i className='mdi mdi-email'></i></a><br/></div>}
                                                        {respondent?.E03 && <div className='mb-2'><div style={{width:"60%",display:"inline-block"}}>{respondent?.E03}</div> &nbsp; <a href={"mailto:"+respondent?.E03} className='btn btn-sm btn-danger'><i className='mdi mdi-email'></i></a><br/></div>}
                                                        {respondent?.E04 && <div className='mb-2'><div style={{width:"60%",display:"inline-block"}}>{respondent?.E04}</div> &nbsp; <a href={"mailto:"+respondent?.E04} className='btn btn-sm btn-danger'><i className='mdi mdi-email'></i></a><br/></div>}
                                                        {respondent?.E05 && <div className='mb-2'><div style={{width:"60%",display:"inline-block"}}>{respondent?.E05}</div> &nbsp; <a href={"mailto:"+respondent?.E05} className='btn btn-sm btn-danger'><i className='mdi mdi-email'></i></a></div>}
                                                        </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>Phone</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>
                                                        {respondent?.P01 && <div className='mb-2'><div style={{width:"60%",display:"inline-block"}}>{respondent?.P01}</div> &nbsp; <button className='btn btn-sm btn-warning'><i className='mdi mdi-phone'></i></button><br/></div>}
                                                        {respondent?.P02 && <div className='mb-2'><div style={{width:"60%",display:"inline-block"}}>{respondent?.P02}</div> &nbsp; <button className='btn btn-sm btn-warning'><i className='mdi mdi-phone'></i></button><br/></div>}
                                                        {respondent?.P03 && <div className='mb-2'><div style={{width:"60%",display:"inline-block"}}>{respondent?.P03}</div> &nbsp; <button className='btn btn-sm btn-warning'><i className='mdi mdi-phone'></i></button><br/></div>}
                                                        {respondent?.P04 && <div className='mb-2'><div style={{width:"60%",display:"inline-block"}}>{respondent?.P04}</div> &nbsp; <button className='btn btn-sm btn-warning'><i className='mdi mdi-phone'></i></button><br/></div>}
                                                        {respondent?.P05 && <div className='mb-2'><div style={{width:"60%",display:"inline-block"}}>{respondent?.P05}</div> &nbsp; <button className='btn btn-sm btn-warning'><i className='mdi mdi-phone'></i></button></div>}
                                                      
                                                        </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>LinkedIn</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>{respondent?.G08} &nbsp;
                                                    {
                                                        (respondent && respondent?.G08) && 
                                                        // <button href={respondent?.G08} target='_blank' className='btn btn-sm btn-primary' onClick={()=>{setPopUpDisplay(true);setPopUpBody(prepareIframe(respondent?.G08));}}><i className='mdi mdi-linkedin'></i> Visit</button>
                                                        <button   className='btn btn-sm btn-primary' onClick={()=>window.open(respondent?.G08, '_blank', 'width=1024,height=600,top=100,left=100' )} ><i className='mdi mdi-linkedin'></i> Visit</button>
                                                         
                                             
                                                    }
                                                    </td>
                                                </tr>
                                                {
                                            (respondent && respondent?.appointmentTime && respondent.appointmentTime != null && respondent.appointmentTime != 0) && 
                                                      
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>Appointment</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>
                                                        {DateFunc.format1(respondent?.appointmentTime*1000,"")}
                                                    </td>
                                                </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-lg-12 p-2">
                                        <button className='btn btn-sm btn-info' onClick={(e)=>movePrev(e)}><i className='mdi mdi-chevron-double-left' ></i> Prev</button>
                                        {
                                            respondent && respondent?.totalCount > 0 && 
                                        <strong style={{paddingLeft:"40%"}}>{respondentIndex+1}/{respondent.totalCount}</strong>
                                        }
                                        <button  className='btn btn-sm btn-info' style={{float:"right"}} onClick={(e)=>moveNext(e)}>Next <i className='mdi mdi-chevron-double-right'></i></button>
                                    </div>
                                    <div class="col-lg-12 mt-3">
                                        <div class="form-theme included-label rounded-4 shadow" style={{overflow:"visible"}}>
                                            <h2 class="h5 bg-light border-bottom border-primary border-4  p-2"> &nbsp; Disposition</h2>
                                            <form class="p-2" onSubmit={saveDisposition}>
                                                <div className='row'>
                                                <div className='col-lg-12'>{message}</div>
                                                <div className='col-lg-12'>

                                                    <div className='row gy-1'>
                                                        <div className='col-6'>
                                                            <div className='form-group'>
                                                                <input type='hidden' name='respondentID'defaultValue={respondent?.ID} />
                                                                <label for="comStatusID" className="form-label">Communication Status</label>
                                                                <select id="comStatusID" name='comStatusID' className="form-select" data-choices data-choices-sorting="true" b2b_required="1" onChange={(e)=>{e.target.value == 1613?setAppointmentDisplay("block"):setAppointmentDisplay("none")}} >
                                                                    <option value={""}  selected>Select communication status</option>
                                                                    {comstatuses?.map((row) => {
                                                                            if(row.name !='New Call')
                                                                            return (<option value={row.id} selected='' >{row.name}</option>)
                                                                        })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className='form-group'>
                                                                <label for="studyNameID" className="form-label">Study Name</label>
                                                                <select id="studyNameID" name='studyNameID' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                    <option value={""}  selected>Select study name</option>
                                                                    {studyNames?.map((row) => {
                                                                            return (<option value={row.ID} selected='' >{row.name}</option>)
                                                                        })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-6' style={{display:appointmentDisplay}} >
                                                            <div className='form-group' >
                                                                <label for="comStatusID" className="form-label">Appointment Time</label>
                                                                
                                                                <DatePicker className='form-control' style={{width:"100%"}}
                                                                                key = "startOn"
                                                                                placeholder="Enter appointment date & time"
                                                                                id="appointmentTime"
                                                                                name ="appointmentTime" 
                                                                                selected={appointmentTime} 
                                                                                onChange={(date) => setappointmentTime(date)}
                                                                                dateFormat="d-MM-yyyy h:mm aa"
                                                                                timeIntervals={5}
                                                                                showTimeSelect
                                                                            />
                                                            </div>
                                                        </div>
                                                        <div className='col-6 mt-3' style={{textAlign:"right"}}>
                                                            <button type="submit" className='my-3 btn btn-md rounded-2 btn-success' > &nbsp; &nbsp; Submit  &nbsp; &nbsp; </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <img src={require("../../image002.png")} style={{width:"100%"}}/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
                </div>
                </div>
                </div>
            <Footer/>

            {popUpDisplay === true ? (<>
                <>
            <CModal visible={popUpDisplay} backdrop={"static"} size={"xl"} onClose={() => setPopUpDisplay(false)} id='surveyModal'>
                <CModalHeader style={{ backgroundColor: "#405189", paddingBottom: "10px", margin: "-1px" }} closeButton={false} >
                    <CModalTitle style={{ color: "#fff", width: "100%" }}>
                        <span style={{ float: "right", cursor: "pointer" }} onClick={() => setPopUpDisplay(false)} > X </span>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                </CModalBody>
            </CModal>
            </>
            </>) : (<></>)}
        </>
    );
};

export default Primary;
