import { deleteAPI, get, postAPI, putAPI } from "../services/ApiService";
import Swal from "sweetalert2";
export const pollList = async({page=0, recPerPage=50, searchKeyword, countryID, languageID}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword,
        countryID: countryID,
        languageID: languageID
    }
    let res = await postAPI('/poll/list', data)
    return res;
}

export const create = async({payload}) => {
    let res = await postAPI('/poll/save', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const detail = async({payload}) => {
    let res = await get('/poll/details/'+payload)
    return res;
}

export const update = async({ID, payload}) => {
    let res = await putAPI('/poll/update/'+ID, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const trash = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/poll/delete/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const validate = (formData) => {
    const{
        countryID, 
        languageID,
        //controlTypeID,
        title,
        options
        //poll,
        
    } = formData;
    const newErrors = {};
    if(!countryID || countryID === 'Select Country') newErrors.countryID = 'Please select country';
    if(!languageID || languageID === 'Select Language') newErrors.languageID = 'Please select language';
    //if(!controlTypeID || controlTypeID === 'Select Control Type') newErrors.controlTypeID = 'Please select control type';
    if(!title || title === '') newErrors.title = 'Please enter poll no.';
    //if(!poll || poll === '') newErrors.poll = 'Please enter poll';
    return newErrors
}

export const pollCheckUniqueTitle = async({payload}) => {
    let res = await postAPI('/poll/check-unique-title/', payload)
    return res
}

export const saveSchedule = async({payload}) => {
    let res = await postAPI('/poll/save-schedule', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const getSchedules = async(payload) => {
    let res = await get('/poll/schedules', payload)
    // return Swal.fire({
    //     text:res.message,
    //     timer: 1500,
    // }).then(() => {
        return res;
    //})
}

export const updatePollSchedule = async({scheduleID,link}) => {
    let res = await postAPI('/poll/schedule/'+link, {scheduleID})
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}