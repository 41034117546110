import React, { useState, useEffect } from 'react'
import { defaultPage } from '../../Common/Constant';
import { userList, userProfile } from '../../api/users';
import { getDownloadFile } from "../../services/ApiService";
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
// import Swal from 'sweetalert2';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react'

function IncompleteUser() {
    useEffect(() => {
        document.title = "Incomplete Panelist - GoToSample"
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [listType, setListType] = useState("incomplete");
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState(defaultPage)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [detail, setDetail] = useState();
    const [visibleDetail, setVisibleDetail] = useState(false);

    useEffect(() => {
        getList()
    }, [listType]);

    const changePage = (pageInfo) => {
        setPageInfo(pageInfo)
    }

    const getList = async() =>{
        if(listType){
            setSearchKeyword(document.getElementById('searchKeyWord').value);            
            let payloadData = {
                listType: listType,
                q: searchKeyword ? searchKeyword : "",
                page: pageInfo.current_page ? pageInfo.current_page : 0, 
                recPerPage: pageInfo.perPage ? pageInfo.perPage : 50,
                from: startDate ? Math.floor(new Date(startDate+' 00:00:00').getTime()/1000) : "",
                to: endDate ? Math.floor(new Date(endDate+' 23:59:59').getTime()/1000) : ""
            }
            let res = await userList({payload: payloadData});
            if(res.status === 1){
                setLists(res.payload);
                setPageInfo(res.paginationInfo);
            } else{
                setLists([])
            }
        }
    }

    const downloadUserData = async () => {
        if(listType){
            // set search keyword
            setSearchKeyword(document.getElementById('searchKeyWord').value);
            let payloadData = {
                listType: listType,
                q: searchKeyword ? searchKeyword : "",
                page: pageInfo.current_page ? pageInfo.current_page : 0, 
                recPerPage: pageInfo.perPage ? pageInfo.perPage : 50,
                from: startDate ? Math.floor(new Date(startDate+' 00:00:00').getTime()/1000) : "",
                to: endDate ? Math.floor(new Date(endDate+' 23:59:59').getTime()/1000) : ""
            }
            
            await getDownloadFile("/user/download/?page="+payloadData.page+"&recPerPage="+payloadData.recPerPage+"&q="+payloadData.q+"&from="+payloadData.from+"&to="+payloadData.to+"&listType="+payloadData.listType, "users_"+Math.floor(new Date().getTime() / 1000));
        }
    };

    const fetchUserProfile = async (id) => {
        let res = await userProfile({ payload: {userId: id} });
        if (res.status === 1) {
            setDetail(res.payload);
            setVisibleDetail(true);
        }
    }
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">Panelist</li>
                                                <li className="breadcrumb-item active">Incomplete</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className='d-flex justify-content-end gap-3'>                                
                                <div className="mb-3 row mx-2">
                                    <label htmlFor="Projectdescription" className="form-label mb-0">Search <span className="required_field">*</span></label>
                                    <input type="text" onKeyDown={(e)=>setSearchKeyword(e.target.value)} className="form-control" placeholder='Search by name, email, phone . . .' id="searchKeyWord" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="startDate" className="form-label mb-0">From <span className="required_field">*</span></label>
                                    <input type="date" onChange={(e)=>setStartDate(e.target.value)} className="form-control" id="startDate" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="endDate" className="form-label mb-0">To <span className="required_field">*</span></label>
                                    <input type="date" onChange={(e)=>setEndDate(e.target.value)} className="form-control" id="endDate" />
                                </div> 
                                <div className="mb-3 d-flex align-items-center">
                                    <button className="btn btn-primary mt-3" onClick={()=>getList()}>
                                        <i className="mdi mdi-magnify"></i> Go
                                    </button>
                                </div>
                                <div className="mb-3">
                                    <button className="btn btn-primary mt-3" onClick={()=>downloadUserData()}>
                                        <i className="mdi mdi-download"></i> Download
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Name</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Phone</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Email</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Country</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Status</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Type</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Joined On</th>
                                                        <th scope="col" style={{textAlign:"left"}}>More</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-body">
                                                    {lists ? (<>
                                                        {lists?.length > 0 ? (<>
                                                            {lists?.map((row, index) => {
                                                                return(<>
                                                                <tr key={index+1} id={row?.ID}>
                                                                    <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                                    <td style={{textAlign:"left"}}>
                                                                        {row?.firstName !== null ? row?.firstName : "-"} {row?.lastName !== null ? row?.lastName : ""}
                                                                    </td>
                                                                    <td style={{textAlign:"left"}}>{row?.phone && row?.phone !== null && row?.phone !== "" ? row?.phone : "-"}</td>
                                                                    <td style={{textAlign:"left"}}>{row?.email}</td>
                                                                    <td style={{textAlign:"left"}}>{row?.countryTxt && row?.countryTxt !== null && row?.countryTxt !== "" ? row?.countryTxt : "-"}</td>

                                                                    <td style={{textAlign:"left"}}>
                                                                        <span className={row?.statusSlug === "verified" ? "" : row?.statusSlug === "blocked" ? "" : ""}>
                                                                            {row?.statusTxt}
                                                                        </span>
                                                                    </td>
                                                                    <td style={{textAlign:"left"}}>
                                                                        <span className={row?.userType === "b2b" ? "" : ""}>
                                                                            {row?.userType.toUpperCase()}
                                                                        </span>
                                                                    </td>
                                                                    <td style={{textAlign:"left"}}>{row?.joinedOn}</td>
                                                                    <td style={{textAlign:"left"}}>
                                                                        <button className="btn btn-md btn-info" onClick={()=>fetchUserProfile(row?.ID)}> 
                                                                            <i className="mdi mdi-eye"></i> View
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                </>)
                                                            })}
                                                        </>) : (<>
                                                            <tr>
                                                                <td colSpan={9}>No data found</td>
                                                            </tr>
                                                        </>)}
                                                    </>) : 
                                                    (<></>)}                                                  
                                                </tbody>                                                
                                            </table>
                                            <Pagination pagination={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end row--> */}
                            {/* <!-- end row --> */}
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer /> 
            {visibleDetail ? (<>
                <>
                    {/* <CButton onClick={() => setVisibleDetail(!visibleDetail)}>Launch static backdrop modal</CButton> */}
                    <CModal visible={visibleDetail} backdrop={"static"} size={"xl"} onClose={() => setVisibleDetail(false)}>
                        <CModalHeader style={{ backgroundColor: "#405189", paddingBottom: "10px", margin: "-1px" }} closeButton={false}>
                            <CModalTitle style={{ color: "#fff", width: "100%" }}>
                                User Profile Info
                                <span style={{ float: "right", cursor: "pointer" }} onClick={() => setVisibleDetail(false)}> X </span>
                            </CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="row d-flex">
                                        <div className="col-md-4">
                                            <label htmlFor="name" className="form-label">Name:</label>
                                            <span> {detail?.personalInfo?.firstName} {detail?.personalInfo?.lastName}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="email" className="form-label">Email:</label>
                                            <span> {detail?.personalInfo?.email}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="phone" className="form-label">Phone:</label>
                                            <span> {detail?.personalInfo?.phone}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="userType" className="form-label">User type:</label>
                                            <span style={{ textTransform: "uppercase" }}> {detail?.userType}</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row d-flex" style={{"margin-top":"30px"}}>
                                        <h5  style={{"margin-bottom":"15px"}}> <span style={{'border-bottom':"3px solid blue"}}>Geo Info</span></h5>
                                        <div className="col-md-4">
                                            <label htmlFor="countryTxt" className="form-label">Country:</label>
                                            <span> {detail?.geoInfo?.countryTxt}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="countryTxt" className="form-label">City:</label>
                                            <span> {detail?.geoInfo?.cityName}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="countryTxt" className="form-label">Postal Code:</label>
                                            <span> {detail?.geoInfo?.postalCode}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="languageTxt" className="form-label">Language:</label>
                                            <span> {detail?.geoInfo?.languageTxt}</span>
                                        </div>
                                    </div>
                                    {/* <hr /> */}
                                    {/* <div className="row d-flex align-items-center">
                                        <h5>Profile Info</h5>
                                        <div className="col-md-4">
                                            <label htmlFor="genderTxt" className="form-label">Gender:</label>
                                            <span> {detail?.demographicInfo?.genderTxt}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="ageGroupTxt" className="form-label">Age Group:</label>
                                            <span> {detail?.demographicInfo?.ageGroupTxt}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="qualificationTxt" className="form-label">Qualification:</label>
                                            <span> {detail?.demographicInfo?.qualificationTxt}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="occupationTxt" className="form-label">Occupation:</label>
                                            <span> {detail?.demographicInfo?.occupationTxt}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="incomeGroupTxt" className="form-label">Income Group:</label>
                                            <span> {detail?.demographicInfo?.incomeGroupTxt}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="relationshipStatusTxt" className="form-label">Relationship Status:</label>
                                            <span> {detail?.demographicInfo?.relationshipStatusTxt}</span>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="familyMemberCountTxt" className="form-label">Family Member Count:</label>
                                            <span> {detail?.demographicInfo?.familyMemberCountTxt}</span>
                                        </div>
                                    </div> */}
                                    {(detail?.profileInfo  )? (<>
                                        <hr />
                                        <div className="row d-flex" style={{"margin-top":"30px"}}>
                                            <h5 style={{"margin-bottom":"15px"}}> <span style={{'border-bottom':"3px solid blue"}}>Profile Info</span></h5>
                                            {Object.keys(detail?.profileInfo)?.map((item,i) => (<>
                                            <div className="col-md-4">
                                                <label htmlFor="industryTxt" className="form-label">{item} :</label>
                                                <span> {detail?.profileInfo[item]}</span>
                                            </div>
                                            </>
                                            ))}
                                            
                                        </div>
                                    </>) : (<></>)}
                                </div>
                            </div>
                        </CModalBody>
                    </CModal>
                </>
            </>) : (<></>)}
        </div>
    )
}

export default IncompleteUser;