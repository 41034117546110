import React, { useState, useEffect } from 'react';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';

import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar'; 
import Footer from '../Layout/Footer';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { masterData } from '../../api/master-data';
import { putAPI,postAPI, uploadImageAPI,get, deleteAPI } from '../../services/ApiService';
import {DateFunc} from '../../services/Date';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Update() {
    const [groups, setGroups] = useState();
    const [countries, setCountries] = useState();
    const [states, setStates] = useState();
    const [currencyCode, setCurrencyCode] = useState();
    const [employeesizes, setEmployeeSizes] = useState();
    const [revenuesizes, setRevenueSizes] = useState();
    const [industries, setIndustries] = useState();
    const [departments, setDepartments] = useState();
    const [designations, setDesignations] = useState();
    const [comstatuses, setComStatus] = useState();
    const [accessstatuses, setAccessStatus] = useState();
    const [respondentIndex, setRespondentIndex] = useState(0);
    const [respondent, setRespondent] = useState({});
    const [studyNames, setStudyNames] = useState([]);
    const [message, setMessage] = useState([]);
    const [popUpDisplay, setPopUpDisplay] = useState(false);
    const [appointmentDisplay, setAppointmentDisplay] = useState("none");
    const [appointmentTime, setappointmentTime] = useState(new Date());//datepicker start date
    
    
    // const [genders, setGenders] = useState();
    // const [agegroups, setAgeGroups] = useState(); 
    // const [educations, setEducations] = useState(); 
    // const [relationships, setRelationships] = useState(); 
     //const [incomegroups, setIncomeGroups] = useState(); 

    const validationSchema = Yup.object().shape({
        selectedSkills: Yup.array().min(1, 'Select at least one skill').required('Skills are required'),
    });


    useEffect(() => {
        document.title = "Research - GoToSample"   
        //if(dataFetchedRef.current){ // to stop double loading of APIs, skips in first mount
           
        
            const getCountries = async() => {
                let res = await masterData({'moduleName':'country'})
                if(res.status === 1){
                    setCountries(res.payload)
                }
            };
            //getCountries();
            const getEmployeeSizes = async() => {
                let res = await masterData({moduleName:'employee-size'})
                if(res.status === 1){
                    setEmployeeSizes(res.payload)
                }
            }
            //getEmployeeSizes();
            const getRevenueSizes = async() => {
                let res = await masterData({moduleName:'company-revenue'})
                if(res.status === 1){
                    setRevenueSizes(res.payload)
                }
            }
            //getRevenueSizes();
            const getDepartments = async() => {
                let res = await masterData({moduleName:'departments'})
                if(res.status === 1){
                    setDepartments(res.payload)
                }
            }
            //getDepartments()
            const getIndustries = async() => {
                let res = await masterData({moduleName:'industry'})
                if(res.status === 1){
                    setIndustries(res.payload)
                }
            }
            //getIndustries();
            const getDesignations = async() => {
                let res = await masterData({moduleName:'titles'})
                if(res.status === 1){
                    setDesignations(res.payload)
                }
            }
            //getDesignations();

            const getStudyNames = async() => {
                let res = await get('/research/study/list',{status:1,current:1}); 
                if(res.status === 1){
                    setStudyNames(res.payload)
                }
            }
            getStudyNames();

            const getComStatus = async() => {
                let res = await masterData({moduleName:'communication-status'})
                if(res.status === 1){
                    setComStatus(res.payload)
                }
            }
            getComStatus();

            const getAccessStatus = async() => {
                let res = await masterData({moduleName:'database-access-status'})
                if(res.status === 1){
                    setAccessStatus(res.payload)
                }
            }
            getAccessStatus();

        getFilters();
    },[]);

    const getStates = async(countryID) =>{
        setStates();

        let data = {countryID}
        let res = await get('/research/primary/states', data);        
            if(res.status === 1){
                setStates(res.payload.states.split("#"));
            }
    }
 
    const getFilters = async() =>{
        let data = {}
        let res = await get('/research/primary/filters', data);        
            if(res.status === 1){
                if(res.payload.countries != null)
                setCountries(res.payload.countries.split("#"));
                if(res.payload.groups != null)
                setGroups(res.payload.groups.split("#"));
                //setStates(res.payload.states);
                if(res.payload.revenues != null)
                    setRevenueSizes(res.payload.revenues.split("#"));
                if(res.payload.employee_sizes != null)
                    setEmployeeSizes(res.payload.employee_sizes.split("#"));
                
                if(res.payload.departments != null)
                setDepartments(res.payload.departments.split("#"));

                if(res.payload.industries != null)
                setIndustries(res.payload.industries.split("#"));

                if(res.payload.job_titles != null)
                setDesignations(res.payload.job_titles.split("#"));
            }else{
            }
    }

    useEffect(() => {
        loadRespondents();
    },[respondentIndex]);

    const searchRespondent = async(e) => {
        e.preventDefault();
        loadRespondents()
    }

    const filterRespondent = async(e) => {
        // Handle form submission
        //console.log(values.selectedSkills);
        e.preventDefault();
        document.getElementById('searchKeyword').value = "";
        let preIndex = respondentIndex;
        setRespondentIndex(0);
        if(preIndex ==0){
            loadRespondents()
        }
    };



    async function  loadRespondents(){  
        let formInput = {};
        const formData = new FormData(document.getElementById('filterForm'));
        for (const [key, value] of formData) {
            formInput[key] = value;           
        }
        let searchKeyword = document.getElementById('searchKeyword').value;
        if(searchKeyword !=""){
            formInput.searchKeyword = searchKeyword;
        }
        formInput.offset = respondentIndex;
        formInput.records = 1;   
    let res = await get('/research/primary/respondent', formInput);        
        if(res.status === 1){
            resetUpdateForm()
            setRespondent(res.payload[0]);
        }else{
            setRespondent({});
        }
    }

    const movePrev = async(e)=>{
        if(respondentIndex > 0){
            setRespondentIndex(respondentIndex-1);
        }
    }
    const moveNext = async(e)=>{

        if(respondent && respondent?.totalCount > 0 && respondentIndex < respondent?.totalCount-1 ){
            setRespondentIndex(respondentIndex+1);
        }        
    }

    const updateData = async(ID,key) => {
        let data = {};
        data[key] = document.getElementById('dataInput_'+key).value;
        let res = await putAPI('/research/primary/respondent/update/'+ID, data);  
        setMessage(res.message)      
            if(res.status === 1){
                resetUpdateForm('',key)
            }else{
            }
       
    };

    const toggleForm = async(e,dataKey) => {
        resetUpdateForm()
        if(document.getElementById('dataInput_'+dataKey).disabled == true){
            document.getElementById('dataInput_'+dataKey).disabled = false;
        document.getElementById('editBtn_'+dataKey).style.display = 'none';
        document.getElementById('updateBtn_'+dataKey).style.display = 'inline-block';
        }else{
            document.getElementById('dataInput_'+dataKey).disabled = true;
        document.getElementById('editBtn_'+dataKey).style.display = 'inline-block';
        document.getElementById('updateBtn_'+dataKey).style.display = 'none';
        }
    }

    function resetUpdateForm(){
        let fields = document.getElementsByClassName('updateField');
        let editBtn = document.getElementsByClassName('editBtns');
        let upBtn = document.getElementsByClassName('updateBtns');
        for(let i =0; i < fields.length; i++){
            fields[i].setAttribute('disabled',true);
            editBtn[i].style.display ='inline-block';
            upBtn[i].style.display = 'none';
        }
    }

    
    // const setCurrency = (countryID)=>{
    //     let country = countries.filter(c=>{
    //         return c.id == countryID
    //     })[0];
    //     console.log(country);
    //     let currency = (JSON.parse(country.description)).currency;
    //     console.log(country);
    //     setCurrencyCode(currency);
    // }

// useEffect(() => {
//     const getIncomeGroups = async() => {
//         if(currencyCode && currencyCode != ""){
//             let res = await masterData({moduleName:'monthly-income-'+currencyCode.toLowerCase()})
//             if(res.status === 1){
//                 setIncomeGroups(res.payload)
//             }
//         }
//     }
//     getIncomeGroups();
// },[currencyCode]);


function prepareIframe(src){
   
//     var x = document.createElement("IFRAME");
//   x.setAttribute("src", "https://www.w3schools.com");
//   document.body.appendChild(x);

return "<iframe src='"+src+"' title='description'></iframe>"
}



    return (
        <>

            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                    {/* <div className='page-title p-4 pb-0'>
                        <h1 className='h5 m-0 text-uppercase'>Researche Database Access</h1>
                    </div> */}
                    <section className=''>
                        <div className='container'>
                            <div className='row g-3'>
                                
                                {/* left form start */}
                                <div className='col-lg-4'>
                                    <div className='form-theme included-label rounded-4 shadow'>
                                        <h2 className='h5 bg-light border-bottom border-primary border-4  p-2'> &nbsp; Choose Criteria</h2>
                                        {/* <Formik initialValues={{ selectedSkills: [] }} validationSchema={validationSchema} onSubmit={loadRespondents}> */}
                                        <form onSubmit={filterRespondent} className='p-3' id="filterForm">
                                            {/* <Form className='p-3'> */}
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className='row gy-1'>
                                                        <div className='col-12'>
                                                                <div className="form-group">
                                                                    
                                                                    {/* <select id="groupId" name='groupId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Group</option>
                                                                        {groups?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select>  */}

                                                                    {groups?.map((row,index) => {
                                                                                return (
                                                                                    <>
                                                                                <input type="radio" name='groupId' id={"groupId"+row} value={row} selected=''  style={{cursor:"pointer"}} />
                                                                                 &nbsp;<label for={"groupId"+row} style={{cursor:"pointer",paddingRight:(index==(groups.length-1))?"0":"40px"}}>{row}</label>
                                                                                </>
                                                                                )
                                                                            })}
                                                                    

                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Country</label>
                                                                    {/* <Select
                                                                        isMulti
                                                                        name="Country"
                                                                        options={countries}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    /> */}
                                                                    <select id="countryId" name='countryId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1" onChange={(e)=>getStates(e.target.value)} >
                                                                        <option value={""}  selected>Select Country</option>
                                                                        {countries?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="Country"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">State</label>
                                                                    {/* <Select
                                                                        isMulti
                                                                        name="stateId"
                                                                        options={[]}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    /> */}
                                                                    <select id="stateId" name='stateId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select State</option>
                                                                        {states?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="State"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Industry</label>
                                                                    <select id="industryId" name='industryId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Industry</option>
                                                                        {industries?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="Industry"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Employee Size</label>
                                                                    <select id="employeeSizeId" name='employeeSizeId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Employee Size</option>
                                                                        {employeesizes?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="EmployeeSize"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Revenue</label>
                                                                    <select id="revenueId" name='revenueId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Revenue</option>
                                                                        {revenuesizes?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="Revenue"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Job Title</label>
                                                                    <select id="jobTitleId" name='jobTitleId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Job Title</option>
                                                                        {designations?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="JobTitle"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Department</label>
                                                                    <select id="departmentId" name='departmentId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Department</option>
                                                                        {departments?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select>
                                                                    {/* <ErrorMessage
                                                                        name="Department"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Data Access Status</label>
                                                                    {/* <Select
                                                                        isMulti
                                                                        name="Department"
                                                                        options={skillsOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    /> */}
                                                                    <select id="accessStatusId" name='accessStatusId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select last access status</option>
                                                                        {accessstatuses?.map((row) => {
                                                                                return (<option value={row.id} selected='' >{row.name}</option>)
                                                                            })}
                                                                    </select>
                                                                    {/* <ErrorMessage
                                                                        name="Department"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Communication Status</label>
                                                                    {/* <Select
                                                                        isMulti
                                                                        name="Department"
                                                                        options={skillsOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    /> */}
                                                                    <select id="communicationStatusId" name='communicationStatusId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select communication status</option>
                                                                        {comstatuses?.map((row) => {
                                                                            return (<option value={row.id} selected='' >{row.name}</option>)
                                                                        })}
                                                                    </select>
                                                                    {/* <ErrorMessage
                                                                        name="Department"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                            <button type="submit" className='w-100 my-3 btn btn-md rounded-2 btn-warning' >Submit</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-lg-6'>
                                                        <div className='row gy-1'>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary mt-3'>No. of Database</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary'>With Email</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary'>Mobile No.</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <button type="button" className='w-100 btn btn-md rounded-2 btn-primary'>Landline No.</button>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessWithEmail" className="form-label">Database Access with email</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" name='AccessWithEmail' placeholder="No. of Database Access with email" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessWithMobile" className="form-label">Database Access with Mobile Number</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" name='AccessWithMobile' placeholder="No. of Database Access with Mobile Number" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessWithContact" className="form-label">Database Access with Contact Number</label>
                                                                    <div className="input-group">
                                                                        <input type="text" className="form-control" name='AccessWithMobile' placeholder="No. of Database Access with Contact Number" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-12'>
                                                                <div className='form-group'>
                                                                    <label for="AccessTo" className="form-label">Access to</label>
                                                                    <select className="form-select" name="AccessTo" required>
                                                                        <option value="">--Select--</option>
                                                                        <option>HR</option>
                                                                        <option>IT</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className='col-12'>
                                                                <button type="submit" className='w-100 my-3 py-3 btn btn-md rounded-2 btn-warning'>Access</button>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            {/* </form> */}
                                        </form>
                                        {/* </Formik> */}
                                    </div>
                                </div>
                                {/* left form end */}
                                <div className='col-lg-8 data-display-table'>
                                    <div className='row'>
                                    <div className=''>
                                        <form onSubmit={searchRespondent}>
                                            <input id='searchKeyword' required className='form-control' placeholder='Search By Name | Company Name | Email | Phone' onChange={()=>{document.getElementById('clearBtn').style.display="block"}} />
                                            <button id="clearBtn"  type='button' onClick={(e)=>{document.getElementById('searchKeyword').value = ''; e.target.style.display='none'}} style={{display:"none",position:"absolute", marginTop:"-31px", right:"18px",border:"1px solid #ccc"}}>X</button>
                                        </form>
                                    </div>
                                        <div className='table-responsive col-md-6' style={{paddingRight:"0"}}>
                                        <table className="table table-theme table-striped table-primary1">
                                            
                                            <tbody>
                                                <tr>
                                                    <td  style={{padding:"0.35rem 0.6rem"}}><b>ID</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>{respondent?.CODE}
                                                    
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>D01</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>{respondent?.D01}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>D02</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                {/* <input id={'dataInput_D02'} className='p-1 form-control' defaultValue={respondent?.D02} disabled /> */}
                                                                 <select id={'dataInput_D02'} name='countryId' className="p-1 form-select updateField" disabled data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                    <option value={""}  >Select Employee Size</option>
                                                                    {employeesizes?.map((row) => {
                                                                            return (<option value={row} selected={respondent?.D02==row?true:false} >{row}</option>)
                                                                        })}
                                                                </select> 
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'D02')} id="updateBtn_D02" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'D02')} id='editBtn_D02'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr><tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>D03</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                {/* <input id={'dataInput_D03'} className='p-1 form-control' defaultValue={respondent?.D03} disabled /> */}
                                                                <select id={'dataInput_D03'} name='countryId' className="p-1 form-select updateField" disabled data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                    <option value={""}  >Select Revenue Size</option>
                                                                    {revenuesizes?.map((row) => {
                                                                            return (<option value={row} selected={respondent?.D03==row?true:false} >{row}</option>)
                                                                        })}
                                                                </select> 
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'D03')} id="updateBtn_D03" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'D03')} id='editBtn_D03'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr><tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>D04</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                {/* <input id={'dataInput_D04'} className='p-1 form-control' defaultValue={respondent?.D04} disabled /> */}
                                                                <select id={'dataInput_D04'} name='countryId' className="p-1 form-select updateField" disabled data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                    <option value={""}  >Select Industry</option>
                                                                    {industries?.map((row) => {
                                                                            return (<option value={row} selected={respondent?.D04==row?true:false} >{row}</option>)
                                                                        })}
                                                                </select>
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'D04')} id="updateBtn_D04" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'D04')} id='editBtn_D04'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>D04A</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>
                                                        <div style={{width:"80%",display:"inline-block"}}>
                                                            <input id={'dataInput_D04A'} className='p-1 form-control updateField' defaultValue={respondent?.D04A} disabled /></div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'D04A')} id="updateBtn_D04A" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'D04A')} id='editBtn_D04A'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>G01</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>
                                                        <div style={{width:"80%",display:"inline-block"}}>
                                                            {/* <input id={'dataInput_G01'} className='p-1 form-control' defaultValue={respondent?.G01} disabled />*/}
                                                            <select id={'dataInput_G01'} name='countryId' className="p-1 form-select updateField" disabled data-choices data-choices-sorting="true" b2b_required="1" onChange={(e)=>getStates(e.target.value)} >
                                                                <option value={""}  >Select Country</option>
                                                                {countries?.map((row) => {
                                                                        return (<option value={row} selected={respondent?.G01==row?true:false} >{row}</option>)
                                                                    })}
                                                            </select> 
                                                        </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'G01')} id="updateBtn_G01" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'G01')} id='editBtn_G01'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>G02</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_G02'} className='p-1 form-control updateField' defaultValue={respondent?.G02} disabled /></div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'G02')} id="updateBtn_G02" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'G02')} id='editBtn_G02'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>G03</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_G03'} className='p-1 form-control updateField' defaultValue={respondent?.G03} disabled /></div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'G03')} id="updateBtn_G03" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'G03')} id='editBtn_G03'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>G04</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_G04'} className='p-1 form-control updateField' defaultValue={respondent?.G04} disabled /></div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'G04')} id="updateBtn_G04" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'G04')} id='editBtn_G04'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>D05</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                {/* <input id={'dataInput_D05'} className='p-1 form-control' defaultValue={respondent?.D05} disabled /> */}
                                                                <select id={'dataInput_D05'} name='countryId' className="p-1 form-select updateField" disabled data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                    <option value={""}  >Select Department</option>
                                                                    {departments?.map((row) => {
                                                                            return (<option value={row} selected={respondent?.D05==row?true:false} >{row}</option>)
                                                                        })}
                                                                </select>
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'D05')} id="updateBtn_D05" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'D05')} id='editBtn_D05'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>                                        
                                        </div>
                                        <div className='table-responsive col-md-6'>
                                            <table className="table table-theme table-striped table-primary1">
                                                
                                                <tbody>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>D06</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_D06'} className='p-1 form-control updateField' defaultValue={respondent?.D06} disabled />
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'D06')} id="updateBtn_D06" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'D06')} id='editBtn_D06'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>D06A</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_D06A'} className='p-1 form-control updateField' defaultValue={respondent?.D06A} disabled />
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'D06A')} id="updateBtn_D06A" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'D06A')} id='editBtn_D06A'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>D06B</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_D06B'} className='p-1 form-control updateField' defaultValue={respondent?.D06B} disabled />
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'D06B')} id="updateBtn_D06B" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'D06B')} id='editBtn_D06B'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>D07</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_D07'} className='p-1 form-control updateField' defaultValue={respondent?.D07} disabled />
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'D07')} id="updateBtn_D07" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'D07')} id='editBtn_D07'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                               
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>E01</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_E01'} className='p-1 form-control updateField' defaultValue={respondent?.E01} disabled />
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'E01')} id="updateBtn_E01" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'E01')} id='editBtn_E01'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>E02</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_E02'} className='p-1 form-control updateField' defaultValue={respondent?.E02} disabled />
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'E02')} id="updateBtn_E02" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'E02')} id='editBtn_E02'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>E05</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_E05'} className='p-1 form-control updateField' defaultValue={respondent?.E05} disabled />
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'E05')} id="updateBtn_E05" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'E05')} id='editBtn_E05'><i className='mdi mdi-pencil'></i></button>
                                                        </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>P01</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_P01'} className='p-1 form-control updateField' defaultValue={respondent?.P01} disabled />
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'P01')} id="updateBtn_P01" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'P01')} id='editBtn_P01'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>P02</b></td>
                                                        <td style={{padding:"0.35rem 0.6rem"}}>
                                                            <div style={{width:"80%",display:"inline-block"}}>
                                                                <input id={'dataInput_P02'} className='p-1 form-control updateField' defaultValue={respondent?.P02} disabled />
                                                            </div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'P02')} id="updateBtn_P02" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'P02')} id='editBtn_P02'><i className='mdi mdi-pencil'></i></button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{padding:"0.35rem 0.6rem"}}><b>N01</b></td>
                                                    <td style={{padding:"0.35rem 0.6rem"}}>
                                                        <div style={{width:"80%",display:"inline-block"}}>
                                                            <input id={'dataInput_N01'} className='p-1 form-control updateField' defaultValue={respondent?.N01} disabled /></div>
                                                            <button className='btn btn-sm btn-info updateBtns' onClick={()=>updateData(respondent?.ID,'N01')} id="updateBtn_N01" style={{display:"none"}}>Save</button>
                                                            <button className='btn btn-sm btn-warning editBtns' style={{float:'right'}} onClick={(e)=>toggleForm(e,'N01')} id='editBtn_N01'><i className='mdi mdi-pencil'></i></button></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{padding:"0.05rem 0.6rem"}}><br/></td>
                                                        <td style={{padding:"0.05rem 0.6rem"}}><br/></td>
                                                </tr>
                                            </tbody>
                                        </table>                                        
                                    </div>
                                    </div>
                                    <div class=" p-2">
                                        <button className='btn btn-sm btn-info' onClick={(e)=>movePrev(e)}><i className='mdi mdi-chevron-double-left' ></i> Prev</button>
                                        {
                                            respondent && respondent?.totalCount > 0 && 
                                        <strong style={{paddingLeft:"40%"}}>{respondentIndex+1}/{respondent.totalCount}</strong>
                                        }
                                        <button  className='btn btn-sm btn-info' style={{float:"right"}} onClick={(e)=>moveNext(e)}>Next <i className='mdi mdi-chevron-double-right'></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                </div>
                </div>
                </div>
            <Footer/>

            {popUpDisplay === true ? (<>
                <>
            <CModal visible={popUpDisplay} backdrop={"static"} size={"xl"} onClose={() => setPopUpDisplay(false)} id='surveyModal'>
                <CModalHeader style={{ backgroundColor: "#405189", paddingBottom: "10px", margin: "-1px" }} closeButton={false} >
                    <CModalTitle style={{ color: "#fff", width: "100%" }}>
                        <span style={{ float: "right", cursor: "pointer" }} onClick={() => setPopUpDisplay(false)} > X </span>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                </CModalBody>
            </CModal>
            </>
            </>) : (<></>)}
        </>
    );
};

export default Update;
