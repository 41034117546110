
import './App.css'
import React from 'react'
import {Routes, Route} from 'react-router-dom';
import LoginPage from './component/LoginPage';
import Dashboard from './component/Dashboard';
import ActiveUser from './component/Panelist/Active';
import IncompleteUser from './component/Panelist/Incomplete';
import DeleteRequestUser from './component/Panelist/DeleteRequested';
import DeletedUser from './component/Panelist/Deleted';

import Question from './component/Question';
import Category from './component/Category';
import Product from './component/Product';
import QuestionSet from './component/QuestionSet.js';
import Survey from './component/Survey';
import Redeem from './component/Redeem';
import Coupon from './component/Coupon';
import Donation from './component/Donation';
import Transaction from './component/Transaction';
import Poll from './component/Poll';
import Role from './component/URM/Role';
import Module from './component/URM/Module';
import Action from './component/URM/Action';
import User from './component/URM/User';

import Respondents from './component/Research/Respondents.js';
import Study from './component/Research/Study.js';
import Primary from './component/Research/Primary.js';
import Screener from './component/Research/Screener.js';
import Report from './component/Research/Report.js';
import Update from './component/Research/Update.js';

function App() {
  return (
    <div className="App">
      <>     
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/panelist/active' element={<ActiveUser />} />
          <Route path='/panelist/incomplete' element={<IncompleteUser />} />
          <Route path='/panelist/delete-request' element={<DeleteRequestUser />} />
          <Route path='/panelist/deleted' element={<DeletedUser />} />

          <Route path='/question' element={<Question />} />
          <Route path='/category' element={<Category />} />
          <Route path='/product' element={<Product />} />
          <Route path='/question-set' element={<QuestionSet />} />
          <Route path='/survey-list' element={<Survey />} />
          <Route path='/redeem' element={<Redeem />} />
          <Route path='/coupon' element={<Coupon />} />
          <Route path='/donation' element={<Donation />} />
          <Route path='/Transaction' element={<Transaction />} />
          <Route path='/poll' element={<Poll />} />
          <Route path='/urm/roles' element={<Role />} />
          <Route path='/urm/modules' element={<Module />} />
          <Route path='/urm/actions' element={<Action />} />
          <Route path='/urm/users' element={<User />} />

          {/* <Route exact path="/" element={<LoginView />}></Route>
          <Route exact path="/signup" element={<SignupView />}></Route> */}
          <Route exact path="/research/respondents" element={<Respondents />}></Route>
          <Route exact path="/research/study" element={<Study />}></Route>
          <Route exact path="/research/primary" element={<Primary />}></Route>
          <Route exact path="/research/screener" element={<Screener />}></Route>
          <Route exact path="/research/report" element={<Report />}></Route>
          <Route exact path="/research/update" element={<Update />}></Route>
        </Routes>
      </>
    </div>
  );
}

export default App;
