const m = ["Jan", "Feb", "Mar", "April", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const DateFunc = {
    format: (unixTimestamp,format) =>{
    const date = new Date(unixTimestamp);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strDate =  m[date.getMonth()] + ' ' + date.getDate() + ' ' + date.getFullYear();
    if(!format || (format && format.includes('hh')) )
    strDate += ' '+hours + ':' + minutes + ' ' + ampm;
    return strDate;
  },

    format1: (unixTimestamp,format) =>{
    const date = new Date(unixTimestamp);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strDate =  m[date.getMonth()] + ' ' + date.getDate() + ' ' + date.getFullYear();
    var strTime = ' '+hours + ':' + minutes + ' ' + ampm;
    return strDate + strTime;
  },
}