import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar'; 
import Footer from '../Layout/Footer';
 import Pagination from '../Layout/Pagination';
import {DateFunc} from '../../services/Date';


import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { masterData } from '../../api/master-data';
import { putAPI,postAPI, uploadImageAPI,get,getDownloadFile, deleteAPI } from '../../services/ApiService';
import { apiBaseURL } from "../../Common/Constant";

function Report() {
    const [message, setMessage] = useState("");//for error handling   
    const [pageInfo, setPagination] = useState({});
    const [lists, setLists] = useState([]);//list of transactions
    const [startDate, setStartDate] = useState(new Date());//datepicker start date
    const [endDate, setEndDate] = useState(new Date());//datepicker end date
    const [studyNames, setStudyNames] = useState([]);


    const getReport = async(e) =>{
        e.preventDefault();
        setMessage();
        
        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
        }
        let res = await get('/research/primary/report', formInput);        
            if(res.status === 1){
                setLists(res.payload)
                setPagination(res.paginationInfo)
            }else{
            setLists([]);
            }
    }

    const changePage =(p)=>{
        pageInfo.current_page = p;
        getReport();
    }

    const getStudyList = async() =>{
        setMessage();        
        let data = {page: pageInfo?.current_page?pageInfo.current_page:0,recPerPage: 50, q: '',typeSlug:'primary-study'}
        let res = await get('/research/study/list', data);        
            if(res.status === 1){
                setStudyNames(res.payload)
            }else{
                setStudyNames([]);
            }
    }

    useEffect(()=>{
        getStudyList();
    },[])

    const downloadUserData = async () => {
            // set search keyword            
            let startDate = document.getElementById("startDate").value;
            let endDate = document.getElementById("endDate").value;
            let studyNameID = document.getElementById("studyNameID").value;
            await getDownloadFile("/research/primary/download?page=1&recPerPage=100&studyNameID="+studyNameID+"&startDate="+startDate+"&endDate="+endDate, "research_report_"+Math.floor(new Date().getTime() / 1000));
    };

    return (
        <>

            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                    {/* <div className='page-title p-4 pb-0'>
                        <h1 className='h5 m-0 text-uppercase'>Researche Database Access</h1>
                    </div> */}
                     
                    <div className='col-lg-12'>
                            {/* <h2 className='h5 bg-light border-bottom border-primary border-4  p-3'>Upload Excel file</h2> */}
                            <form class="p-3 row" onSubmit={getReport}>
                                <div className='p-1'>{message}</div>
                                <div class="col-md-3"  >
                                    <label>Study Name</label>
                                    <select id="studyNameID" name='studyNameID' className="form-select" data-choices data-choices-sorting="true" b2b_required="1" required >
                                        <option value={""}  selected>Select study name</option>
                                        {studyNames?.map((row) => {
                                            return (<option value={row.ID} selected='' >{row.name}</option>)
                                        })}
                                    </select>
                                </div>
                                <div class="col-md-2"  >
                                    <label>Start Date</label>
                                    <DatePicker className='form-control' selected={startDate} onChange={(date) => setStartDate(date)} name="startDate" id="startDate" dateFormat="d-MM-yyyy" />

                                </div>
                                <div class="col-md-2"  >
                                    <label>End Date</label>
                                    <DatePicker className='form-control' selected={endDate} onChange={(date) => setEndDate(date)} name="endDate" id="endDate" dateFormat="d-MM-yyyy"  />

                                </div>
                                <div class="col-md-2 mt-4"  >
                                    <button  class="btn btn-md btn-success" type = 'submit' name=''>Submit</button>
                                    &nbsp; <button  class="btn btn-md btn-danger" type = 'reset' name=''>Reset</button>
                                </div>
                                <div className="col-md-3 mb-3" style={{textAlign:"right"}}>
                                    <button type="button" className="btn btn-primary mt-3" onClick={()=>downloadUserData()}>
                                        <i className="mdi mdi-download"></i> Download
                                    </button>
                                </div>
                            </form>
                    </div>
                    <hr/>

                    <div className="row mt-2">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">

                                {/* <!-- end card header --> */}

                                <div className="card-body" style={{overflow:"auto"}}>
                                    <table className="table table-striped table-hover table_client1" style={{"text-align":"left"}}>
                                        <thead>
                                            <tr className="bg-primary" style={{ color: "white" }}>
                                                <th scope="col">Associate Name</th>
                                                <th scope="col">Total Call</th>
                                                <th scope="col">Complete Survey</th>
                                                <th scope="col">Partial Survey</th>
                                                <th scope="col">Recruitment</th>
                                                <th scope="col">No Response</th>
                                                <th scope="col">Call Later</th>
                                                <th scope="col">Not Right Respondent</th>
                                                <th scope="col">Not Ineterested</th>
                                                <th scope="col">User Busy</th>
                                                <th scope="col">Call Disconnect</th>
                                                <th scope="col">Wrong Number</th>
                                                <th scope="col">Appointment</th>
                                                <th scope="col">Voice Mail</th>
                                                <th scope="col">Email</th>
                                            </tr>
                                        </thead>
                                        {(lists && lists.length > 0 )? (
                                        <tbody>
                                            {lists?.map((list,index) => (
                                                <tr key={list?.ID}>
                                                <td>{list.associateName}</td>
                                                <td>{list.totalCall}</td>
                                                <td>{list.totalCompleteSurvey}</td>
                                                <td>{list.totalPartial}</td>
                                                <td>{list.totalRecruitment}</td>
                                                <td>{list.totalNoResponse}</td>
                                                <td>{list.totalCallLater}</td>
                                                <td>{list.totalNotRight}</td>
                                                <td>{list.totalNotInterested}</td>
                                                <td>{list.totalUserBusy}</td>
                                                <td>{list.totalCallDisconnect}</td>
                                                <td>{list.totalWrongNumber}</td>
                                                <td>{list.totalAppointment}</td>
                                                <td>{list.totalVoiceMail}</td>
                                                <td>{list.totalEmail}</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                        ):(<>
                                            <tr>
                                                <td colSpan={9} id="dataMessage" style={{padding:"50px 0"}}>No data found</td>
                                            </tr>
                                        </>)}
                                    </table>
                                    <Pagination pageInfo={pageInfo} changePage={changePage} />
                                </div>
                            </div>
                        </div>
                    </div>

                           

                </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Report;
