import React, { useState, useEffect } from 'react';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';

import { defaultPage } from '../Common/Constant';
import * as Pollapi from '../api/poll';
import { masterData } from '../api/master-data';
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import Pagination from './Layout/Pagination';
import { CategoryList, ProductList } from '../api/common';
import { putAPI,postAPI, get, uploadImageAPI } from '../services/ApiService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {DateFunc} from '../services/Date';


function Poll() {
    useEffect(() => {
        document.title = "Poll - GoToSample"
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPagination] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [countries, setCountries] = useState();
    const [languages, setLanguages] = useState();
    const [submitBtnTxt, setSubmitBtnTxt] = useState('Submit')
    const [countryID, setCountryId] = useState()
    const [languageID, setLanguageId] = useState()
    // const [controlTypeId, setControlTypeId] = useState()
    const [title, setTitle] = useState("");
    const [addOption, setAddOption] = useState()
    const [mappedOption, setMappedOption] = useState([])
    const [errors, setErrors] = useState({});    
    const [pollDetail, setDetail] = useState({});
    const [ID, setID] = useState();
    const [categories, setCategories] = useState();
    const [products, setProducts] = useState();
    const [categoryID, setCategoryID] = useState();
    const [productID, setProductID] = useState();
    const [historyDisplay, sethistoryDisplay] = useState(false);
    const [startDate, setStartDate] = useState(new Date());//datepicker start date
    const [endDate, setEndDate] = useState(new Date());//datepicker end date
    const [schedules, setSchedules] = useState([])

    //const now = Math.floor(Date.now() / 1000);
    const now =  Math.floor(new Date().getTime()/1000)


    const allowNumeric = async (e) => {
        // allow only numberic value, delete, backspace, arrow left, arrow right, tab
        if(!/[0-9]/.test(e.key) && e.key !== 'Delete' && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Tab'){
            e.preventDefault();
        }
    }
    
    const getCountries = async() => {
        let res = await masterData({moduleName:'country'})
        if(res.status === 1){
            setCountries(res.payload)
        }
    }

    const getLanguages = async() => {
        let res = await masterData({moduleName:'language',countryID:countryID})
        if(res.status === 1){
            setLanguages(res.payload)
        }
    }

    const getCategories = async() => {
        if(categoryID === undefined){
            let res = await CategoryList();
            if(res.status === 1){
                setCategories(res.payload);
            }
        }
    }

    const getProducts = async() => {
        if(categoryID){
            let res = await ProductList({payload: '?categoryID='+categoryID});
            if(res.status === 1){
                setProducts(res.payload);
            }
        }
    }

    useEffect(() => {
        getCountries()
        getCategories()
        // getLanguages()
        //getControlType()
    },[])

    useEffect(() => {
        if(countryID){
            getLanguages()
        }
    },[countryID])
    
    useEffect(() => {
        getProducts()
    },[categoryID]);

    // const getControlType = async() => {
    //     let res = await masterData({moduleName:'control-type'})
    //     if(res?.status === 1){
    //         setControlType(res.payload)
    //     }
    // }

    
    function setCatFilter(catId){
        setProductID();
        setCategoryID(catId);
        //document.getElementById('categoryFilter').value = catId;
    }

    function setProductFilter(prodId){
        setProductID(prodId)
        //document.getElementById('productFilter').value = prodId;
    }

    const getList = async() =>{
        if(countryID && languageID){
        let data = {
            page: pageInfo.current_page,
            recPerPage: 50,
            q: searchKeyword,
            countryID: countryID,
            languageID: languageID
        }

            //let res = await pollList({page:0, searchKeyword:searchKeyword, countryID: countryID, languageID: languageID});
            let res = await get('/poll/list', data)

            if(res.status === 1){
                setLists(res.payload)
                setPagination(res.paginationInfo)
            }else{
                setLists([])
            }
        }
        
    }

    
    useEffect(() => {
        getList();
    },[countryID, languageID])



    const handleAddOption = (e) => {
        setAddOption(e.target.value)
        // setForm({...form, ['description']:e.target.value})
    }

    const removeMapped = (item) => {
        let mappedList = mappedOption.filter(maped => maped != item);
        setMappedOption(mappedList);
    }

    const moveToMappedOption = async () => {
        addOption?.split('\n').map(function (item, i) {
            if (item !== '' || item !== null) {
                if (mappedOption?.indexOf(item) === -1) {
                    setMappedOption((mappedOption) => [...mappedOption, item])
                }
            }

        })
        await setAddOption("")
    }

    const handlesubmit = async (e) => {
        e.preventDefault();
        setErrors();
        // let formData = {
        //     options:mappedOption,
        //     countryID:countryID,
        //     languageID:languageID,
        //     // controlTypeID:controlTypeId,
        //     title:title,
        //     description:null
        // };

        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
        }
        formInput.options = mappedOption;

        const formErrors = Pollapi.validate(formInput)
        if(Object.keys(formErrors).length > 0){ console.log(formErrors);
            setErrors(formErrors)
        }else{
            let res = {};
            if(ID && ID != null && ID != '' && ID != 0){
                res = await Pollapi.update({ID, payload:formInput});
            }else{
                res = await Pollapi.create({payload:formInput});
            }
            if(res.status === 1){
                //setControlTypeId()
                setTitle("")
                document.getElementById('title').value="";
                setAddOption()
                setMappedOption([])
                getList();
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        }
    }
    const handleQueTitle = async (e) => {
        if(!/[0-9a-zA-Z_]/.test(e.key)){
            e.preventDefault();
        }
    }

    const editForm = async(ID) => {
        setShowForm(true);
        let res = await Pollapi.detail({payload:ID})
        if(res.status === 1){
            let data = res.payload;
            setID(data.ID)
            setCountryId(data.countryID)
            setLanguageId(data.languageID)
            //setControlTypeId(data.controlTypeID)
            setTitle(data.title)

            let opsDt = []
            data.options.forEach(ele => {
                // opsDt.push(ele?.option);
                 opsDt.push(ele);
            });
            setMappedOption(opsDt)
            setSubmitBtnTxt('Update')
            
            // scroll to top
            window.scrollTo(0, 0)
        }
    }

    const deleteRow = async (id) => {
        let res = await Pollapi.trash({id:id})
        if(res.status === 1){
            getList()
        }
    }

    const changePage =(p)=>{
        pageInfo.current_page = p;
        getList();
    }

    const resetForm = () => {
        setID()
        setCountryId()
        setLanguageId()
        //setControlTypeId()
        setTitle("")
        setAddOption("");
        setMappedOption([])
        setSubmitBtnTxt('Submit')
        document.getElementById('pollForm').reset()
        setErrors({})
    }

    function toggle(e,categoryControlID){
        if(document.getElementById(categoryControlID).style.display == "flex"){
            document.getElementById(categoryControlID).style.display = "none";
            e.target.innerHTML = 'add +';
        }else{
            document.getElementById(categoryControlID).style.display = "flex";
            e.target.innerHTML = 'hide -';

        }
    }

    const submitSchedule =async(e)=>{
        e.preventDefault();
        setErrors();

        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
        }
        formInput.pollID = ID;
        console.log(ID);

        // const formErrors = Pollapi.validate(formInput)
        // if(Object.keys(formErrors).length > 0){ //console.log(formErrors);
        //     setErrors(formErrors)
        // }else{
            let res = await Pollapi.saveSchedule({payload:formInput});
            if(res.status === 1){
                getSchedules()
                //setControlTypeId()
                //getList();
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        //}
    }

    const updatePollSchedule =async(scheduleID,link)=>{
        //e.preventDefault();
        setErrors();        
        let res = await Pollapi.updatePollSchedule({scheduleID,link});
        if(res.status === 1){
            getSchedules()
        }else{
            setErrors({...errors,['submit']:res.message})
        }
    }
    

    const getSchedules = async()=>{
    if(historyDisplay && historyDisplay === true){
        let resp = await Pollapi.getSchedules({pollID:ID});
        if(resp.status == 1){
            setSchedules(resp.payload);
            console.log(schedules);
        }else{
            setSchedules([]);
        }
    }
}
    
    useEffect(() => {
        getSchedules()
    },[historyDisplay])

    const toggleForm = async(e)=>{
        if(showForm === false){
            setShowForm(true);
            e.target.innerHTML = " Hide Form - ";
        }else{
            setShowForm(false);
            e.target.innerHTML = " Add Poll + ";
        }
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Poll</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item active">Poll</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body" style={{minHeight:"50px"}}>
                                            <button type='button' className='btn btn-primary' onClick={(e)=>toggleForm(e)} style={{position:"absolute",top:"8px",right:"10px"}}> Add Poll + </button>
                                            <div className="">
                                                {
                                                    showForm && (
                                                        <>
                                                <form id="pollForm"  onSubmit={handlesubmit}>
                                                    <div className="row">
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="countryID" className="form-label mb-0"> Country <span className="required_field">*{errors?.countryID}</span></label>
                                                                <select id="countryID" name="countryID" className="form-select" data-choices data-choices-sorting="true"  onChange={(e)=> setCountryId(e.target.value)}>
                                                                    <option value={""}  selected>Select Country</option>
                                                                    {countries?.map((row) => {
                                                                                    return (<option value={row.id} selected={row.id == countryID ? true : false}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="languageID" className="form-label mb-0"> Language <span className="required_field">*{errors?.languageID}</span></label>
                                                                <select id="languageID" name="languageID" className="form-select" data-choices data-choices-sorting="true" 
                                                                    onChange={(e) => setLanguageId(e.target.value)}
                                                                    disabled={countryID ? false : true}>
                                                                    <option value={""}  selected>Select Language</option>
                                                                    {languages?.map((row) => {
                                                                                    return (<option value={row.id} selected={row.id == languageID ? true : false}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                       
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="title" className="form-label mb-0">Title <span className="required_field">*{errors?.title}</span></label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    placeholder="Enter Poll" 
                                                                    id="title" 
                                                                    name="title" 
                                                                    value={title}
                                                                    //disabled={countryID && languageID ? false:true} 
                                                                    onChange={(e) => setTitle(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        
                                                        {/* {showOption ? (<> */}
                                                            {/* <div className="form_divide"><hr ></hr></div> */}
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="addOption" className="form-label mb-0">Add Option <span className="required_field">*{errors?.description}</span></label>
                                                                                <textarea className="form-control" id="addOption" rows="4" onChange={(e) => handleAddOption(e)} value={addOption}></textarea>
                                                                                {/* <span className="max_char">Maximum limit of characters 250</span> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-1">
                                                                <div className="text-center" style={{marginTop:"60px", marginLeft:"-20px"}}>
                                                                    <span className="btn add_qustion btn-info btn-sm" style={{color:"white", cursor:"pointer"}} onClick={() => moveToMappedOption()}>Add &gt;&gt;</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="mb-3">
                                                                    <label className="form-label mb-0">Mapped Option <span className="required_field">*{errors?.options}</span></label>
                                                                    
                                                                                <div style={{height:"95px", overflowY:"scroll", border:"1px solid", padding: "0.5rem 0.9rem", borderColor:"#ced4da", borderRadius:"4px"}}>
                                                                    {mappedOption?.map((item) => {
                                                                        return (<><div style={{cursor:"pointer","padding":"5px"}} >{item} &nbsp; &nbsp; <span style= {{color:"red",border:"1px solid red","font=weight":"bold",cursor:"pointer",padding:"0 5px"}} onClick={() => removeMapped(item)}>x</span></div></>)
                                                                    })}
                                                                    </div>
                                                                                {/* <span className="max_char">Maximum limit of characters 250</span> */}
                                                                </div>
                                                            </div>

                                                            
                                                        {/* </>) : (<>
                                                            
                                                        </>)} */}
                                                    </div>
                                                    <div className='row'>                                                            
                                                            
                                                            <div className="col-lg-12">
                                                                <div className="text-end">
                                                                    {/* {submitBtnTxt === 'Update' ? (
                                                                        <span style={{cursor:"pointer"}} className="btn btn-primary mx-4" onClick={() => handleUpdate()}>{submitBtnTxt}</span>
                                                                    ) : ( */}
                                                                        <button type='submit' style={{cursor:"pointer"}} className="btn btn-primary mx-4" >{submitBtnTxt}</button>
                                                                    {/* )} */}

                                                                    <a className="btn btn-danger" onClick={() => resetForm()}>
                                                                        <i className="mdi mdi-refresh"></i> Reset
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                                        </>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <select  className="form-select" data-choices data-choices-sorting="true"  onChange={(e)=> setCountryId(e.target.value)} >
                                                            <option value={""}  selected>Select Country</option>
                                                            {countries?.map((row) => {
                                                                return (<option value={row.id} selected={row.id == countryID ? true : false}>{row.name}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <select  className="form-select" data-choices data-choices-sorting="true" 
                                                            onChange={(e) => setLanguageId(e.target.value)}
                                                            disabled={countryID ? false : true}>
                                                            <option value={""}  selected>Select Language</option>
                                                            {languages?.map((row) => {
                                                                return (<option value={row.id} selected={row.id == languageID ? true : false}>{row.name}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{color:"white"}}>
                                                        <th scope="col">Sr. No.</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Poll</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Options</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Schedules</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, index) => {
                                                            return(<>
                                                            <tr>
                                                            <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <strong><span style={{textAlign:"left"}}>{row?.title}</span></strong>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>{row?.options!=null&&row?.options.map((opt)=>{return(

                                                                    <span>{opt}<br/></span>
                                                                )
                                                                })}</td>
                                                                <td style={{textAlign:"left"}}>
                                                               
                                                                Upcoming: {row.schedules} <br/>
                                                                Running/Closed: {row.conducted} <br/>

                                                                <button className='btn btn-sm btn-danger' onClick={(e)=>{sethistoryDisplay(true);setID(row.ID)}}>Manage</button>
                                                               
                                                                </td>
                                                                <td>                                                                   
                                                                    <div className="dropdown align-items-right">
                                                                    <div className="dropdown ms-sm-3 header-item topbar-user">
                                                                        <button type="button" className="btn btn-sm btn-success" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{'font-size':"14px"}}>
                                                                        More <i className="mdi mdi-dots-vertical-circle" ></i>
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}}  onClick={() => editForm(row?.ID)} ><i className="mdi mdi-pencil"></i> Edit</a>
                                                                            <div className="dropdown-divider"></div>
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={() => deleteRow(row?.ID)} ><i className="mdi mdi-delete"></i> Delete</a>                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </td>
                                                                
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={6}>No data found</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />


            {historyDisplay ? (<>
                <>
            <CModal visible={historyDisplay} backdrop={"static"} size={"xl"} onClose={() => {sethistoryDisplay(false);setID()}}>
                        <CModalHeader style={{ backgroundColor: "#405189", paddingBottom: "10px", margin: "-1px" }} closeButton={false} >
                            <CModalTitle style={{ color: "#fff", width: "100%" }}>
                                Manage Poll Schedule - {pollDetail?.title}
                                <span style={{ float: "right", cursor: "pointer" }} onClick={() => {sethistoryDisplay(false);setID()}} > X </span>
                            </CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <form className="row mt-2" id='scheduleForm' onSubmit={submitSchedule}>
                                <div id='ta-message' style={{color:"red"}}>{errors?.manageSchedule}</div> 
                                <input type="hidden" name="ID"/>

                                <div className='row'  >
                                    <div className="col-md-2">
                                        <div className="mb-3">
                                            Category
                                            <select nidame="categoryID" name="categoryID" className="form-select" onChange={(e) => setCatFilter(e.target.value)} disabled={pollDetail.ID?true:false} >
                                                <option value={""}  selected>Category</option>
                                                {categories?.map((ctg) => (<option value={ctg.id} selected={ctg.id === categoryID ? true : false}>{ctg.name}</option>)
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        Product
                                        <div className="mb-3">
                                            
                                            <select id="productID" name="productID" className="form-select" onChange={(e) => {setProductFilter(e.target.value);setProductID(e.target.value)}} disabled={categoryID ? (pollDetail.ID?true:false) : true}>
                                                <option value={""}  selected={productID ? false : true}>Product</option>
                                                {products?.map((prd) => (<option value={prd.id} selected={prd.id == pollDetail.productID ? true : false}>{prd.name}</option>)
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-1' style={{padding:"0 2px"}}>
                                        B2C Value*
                                        <input className='form-control' placeholder='B2C Points' id="B2Cvalue" name="B2Cvalue" defaultValue={0} required="1" />
                                    </div>
                                    <div className='col-md-1' style={{padding:"0 2px"}}>
                                        B2B Value*
                                        <input className='form-control' placeholder='B2B Points' id="B2Bvalue" name="B2Bvalue" defaultValue={0} required="1" />
                                    </div>
                                    <div className='col-md-2'>
                                        Start Date*
                                        {/* <input className='form-control' placeholder='Start Date Points' /> */}
                                <DatePicker className='form-control' selected={startDate} onChange={(date) => setStartDate(date)} name="startDate" id="startDate" dateFormat="d-MM-yyyy" required="1" />

                                    </div>
                                    <div className='col-md-2'>
                                        {/* <input className='form-control' placeholder='End Date' /> */}
                                        End Date*
                                <DatePicker className='form-control' selected={endDate} onChange={(date) => setEndDate(date)} name="endDate" id="endDate" dateFormat="d-MM-yyyy" required="1" />

                                    </div>
                                    {/* <div className='col-md-1' style={{padding:"0 2px"}}><br/>
                                        <input name='autoStart' id="autoStart" type='checkbox' /> Auto Start<br/> 
                                        - (Poll will start automatically when time reached)
                                    </div> */}
                                    <div className='col-md-1 text-end' style={{padding:"0 2px"}}><br/>
                                        <button type="submit" className='btn btn-primary'>Submit</button>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <br/><hr/>
                                     <div className='col-md-6' style={{height:"400px",overflow:"auto",border:"1px dashed #ccc"}}>
                                        <h4 style={{background:"#ccc",padding:"5px"}}>Schedules</h4>
                                        <table className='table'>
                                            <tr>
                                            <th>Scheduled Date</th>
                                            <th>Category<br/>/Product</th>
                                            <th>B2C<br/>value</th>
                                            <th>B2B<br/>value</th>
                                            <th>Status</th>
                                            </tr>
                                            {schedules.map(ps=>{
                                                    return(
                                                        ps.status == 0?(
                                                            <tr >
                                                                <td style={{padding:"20px 0"}}>
                                                                {DateFunc.format(ps?.startOn*1000,"dd mmm yyyy hh:mm ampm")} 
                                                                <br/> - - - - - To - - - - -<br/>
                                                                {DateFunc.format(ps?.endOn*1000,"dd mmm yyyy hh:mm ampm")}
        
                                                                </td>
                                                                <td>
                                                                    {
                                                                        (ps.category && ps.product)?
                                                                            (
                                                                                <>
                                                                                {ps.category}<br/>/{ps.product}
                                                                                </>
                                                                            ):(
                                                                                "All"
                                                                            )
                                                                        
                                                                    }
                                                                </td>
                                                                <td>{ps.B2Cvalue}</td><td>{ps.B2Bvalue}</td>
                                                                <td>
                                                                    {
                                                                        (ps.startOn <= now && ps.endOn >= now) ? 

                                                                        ( 
                                                                            <>
                                                                            <span style={{color:"green"}}>Ready</span> 
                                                                            <br/>
                                                                            <button type="button" className='btn btn-md btn-danger' style={{color:"#000",padding:"3px 10px"}} onClick={(e)=>updatePollSchedule(ps.ID,'start')}>Start</button>
                                                                            </>
                                                                        ):( <>
                                                                            {
                                                                                ps.startOn > now && (
                                                                                    <>Upcoming</>
                                                                                )
                                                                            }
                                                                            {
                                                                                ps.endOn < now && (
                                                                                    <span style={{color:"red"}}>Expired</span>
                                                                                )
                                                                            }
                                                                        </>
                                                                        )
                                                                    }
                                                                    
                                                                </td>
                                                            </tr>
                                                        ):(
                                                            <></>
                                                        )
                                                        
                                                    )                                                
                                                })
                                            }
                                        </table>
                                     </div>
                                     <div className='col-md-6' style={{height:"400px",overflow:"auto",border:"1px dashed #ccc"}}>                                        
                                        <h4 style={{background:"#ccc",padding:"5px"}}>Running/Conducted</h4>
                                        <table className='table'>
                                            <tr>
                                            <th>Scheduled Date</th>
                                            <th>Category<br/>/Product</th>
                                            <th>B2C<br/>value</th>
                                            <th>B2B<br/>value</th>
                                            <th>Status</th>
                                            </tr>
                                            {schedules.map(ps=>{
                                                    return(
                                                        ps.status > 0?(
                                                            <tr >
                                                                <td style={{padding:"20px 0"}}>
                                                                {DateFunc.format(ps?.startOn*1000,"dd mmm yyyy hh:mm ampm")} 
                                                                <br/> - - - - - To - - - - -<br/>
                                                                {DateFunc.format(ps?.endOn*1000,"dd mmm yyyy hh:mm ampm")}
        
                                                                </td>
                                                                <td>
                                                                    {
                                                                        (ps.category && ps.product)?
                                                                            (
                                                                                <>
                                                                                {ps.category}<br/>/{ps.product}
                                                                                </>
                                                                            ):(
                                                                                "All"
                                                                            )
                                                                        
                                                                    }
                                                                </td>
                                                                <td>{ps.B2Cvalue}</td><td>{ps.B2Bvalue}</td>
                                                                <td>
                                                                    {
                                                                        ps.status == 1 && (
                                                                            <>Running<br/>
                                                                            <button type="button" className='btn btn-md btn-danger' style={{color:"#000",padding:"3px 10px"}} onClick={(e)=>updatePollSchedule(ps.ID,'close')}>Close</button>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        ps.status == 2 && (
                                                                            <span style={{color:"green"}}>Closed</span>
                                                                        )
                                                                    }
                                                                
                                                                </td>
                                                            </tr>
                                                        ):(
                                                            <></>
                                                        )
                                                        
                                                    )                                                
                                                })
                                            }
                                        </table>
                                     </div>
                                </div>
                                
                            </form>
                        </CModalBody>
            </CModal>
            </>
            </>) : (<></>)}


        </div>
    )
}

export default Poll