import React from 'react'
import { useEffect, useState, useRef } from "react";
import { s3bucket, apiBaseURL } from '../Common/Constant';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import Footer from './Layout/Footer';
import Pagination from './Layout/Pagination';

import { putAPI,postAPI, get, uploadImageAPI } from '../services/ApiService';

function Category() {
    useEffect(() => {
        document.title = "Category - GoToSample"
    },);
    const [categories, setCategory] = useState([]);
    const [pageInfo, setPagination] = useState({});
    const [fileList, setFileList] = useState(null);
    const token = localStorage.getItem("token");
    const headers = {
        // 'Accept': 'application/json',
         'Content-Type': 'application/json',
        // 'Origin': '',
        // 'Host': 'api.producthunt.com'
        'Authorization': 'Bearer '+token+''
    };
    const changePage =(p)=>{
        pageInfo.current_page = p;
        getList();
    }
    const getList = async(page=1) => {
        let data = {page: pageInfo.current_page,recPerPage: 50, q: "",func:''}
        let res = await get('/category/list', data);        
            if(res.status === 1){
               // let data = res;//.json();
            let respArr = [];
                    respArr = res.payload;
                respArr.forEach((r, index) => { r.serial = index + 1; });
                
                 setCategory(respArr);
                 setPagination(res.paginationInfo);
            }
      }

const handleChange = async (e) => {
    let file = e.target.files;
    var fileElement = file[0];
    var fileExtension = "";
    if (fileElement.name.lastIndexOf(".") > 0) {
        fileExtension = fileElement.name.substring(fileElement.name.lastIndexOf(".") + 1, fileElement.name.length);
    }
    // if (fileExtension.toLowerCase() == "doc" || fileExtension.toLowerCase() == "docx" || fileExtension.toLowerCase() == "pdf" || fileExtension.toLowerCase() == "xls" || fileExtension.toLowerCase() == "xlsx") {
        //setfileerr("");
        let res = await uploadImageAPI('/upload/common/', file)
        console.log('res', res)
        if (res.status === 1) {
            //setSelectedFile(res.url)
            document.getElementById("categoryFileUrl").value = res.url;
           
        }

    // }
    // else {
    //     setfileerr("You must select a doc/xls/pdf file for upload");
    //     return false;
    // }
}


const createCategory = async({payload}) => {
    let res = await postAPI('/category/add', payload)
    // return Swal.fire({
    //     text:res.message,
    //     timer: 1500,
    // }).then(() => {
         return res;
    // })
}

const updateCategory= async({id,payload}) => {
    let res = await putAPI('/category/update/'+id, payload)
    // return Swal.fire({
    //     text:res.message,
    //     timer: 1500,
    // }).then(() => {
         return res;
    // })
}

const submitCategory = async (e) => {
    // e.preventDefault();
    //setErrors();
    let name = document.getElementById('categoryName').value;
         let imageURL = document.getElementById('categoryFileUrl').value;
    let formData = {
        name:name,
        imageURL:imageURL
    };
    // const formErrors = validateFormData(formData)
    // if(Object.keys(formErrors).length > 0){
    //     //setErrors(formErrors)
    // }else{
        let res = {};
        if(document.getElementById('categoryId').value == ""){
            res = await createCategory({payload:formData});
        }else{
            let categoryId = document.getElementById('categoryId').value;
            res = await updateCategory({id:categoryId,payload:formData});           
        }
        if(res.status === 1){
            setCategoryFields();
            getList();           
         }
        //else{
        //     //setErrors({...errors,['submit']:res.message})
        // }
    //}
}


const categoryUpdateStatus = async({id, status,payload}) => {
    let res = await putAPI('/category/update-status/'+id+'/'+status, payload)
    // return Swal.fire({
    //     text:res.message,
    //     timer: 1500,
    // }).then(() => {
         return res;
    // })
}

const updateCategoryStatus = async (catId,status) => {
    // e.preventDefault();
    //setErrors();
    status = status==0?1:0;
    let formData = {
        id:catId,
        status:status
    };
    // const formErrors = validateFormData(formData)
    // if(Object.keys(formErrors).length > 0){
    //     //setErrors(formErrors)
    // }else{
        let res = await categoryUpdateStatus({id:catId,status:status, payload:formData});
        if(res.status === 1){
            getList();
           
         }
        //else{
        //     //setErrors({...errors,['submit']:res.message})
        // }
    //}
}


const setCategoryFields = (id,name,url)=>{
    document.getElementById('categoryId').value = id?id:'';
    document.getElementById('categoryName').value = name?name:'';
    document.getElementById('categoryFileUrl').value = url?s3bucket+url:'';
    if(!id){
        document.getElementById('cancelButton').style.display = 'none';
        document.getElementById('categoryFile').value = '';
        
    }else{
        document.getElementById('cancelButton').style.display = 'inline-block';
    }
    if(!url){        
        document.getElementById('imageView').style.display = 'none';
        document.getElementById('imageView').src = '';
    }else{
        document.getElementById('imageView').style.display = 'inline-block';
        document.getElementById('imageView').src = s3bucket+url;
    }
}


    // drag and drop function 
    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
    dragItem.current = position;
    };

    const dragEnter = (e, position) => {
    dragOverItem.current = position;
    };
 
    const drop = (e) => {
    const dragItemContent = categories[dragItem.current];
    categories.splice(dragItem.current, 1);
    categories.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setCategory([...categories]);
    resetPrirority();
};

const resetPrirority=async()=>{
    let priorities = []
    // console.log(lists);

    categories.map(item=>{
        priorities.push(item.id);
    // console.log(item.id);

    })
    console.log(priorities);
    let res = await postAPI('/category/reset-priority', {priorities})
}


      useEffect(() => {
        getList()
      }, [])

    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Add Category</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                {/* <li className="breadcrumb-item"><a >Dashboards</a></li> */}
                                                <li className="breadcrumb-item active">Add Category</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Category </label>
                                        <input type="text" className="form-control" placeholder="type category ..." id="categoryName" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Image (Dimension: 200 X 200 pixels)</label>
                                        <input type="file" className="form-control" placeholder="type category image" id="categoryFile" onChange={handleChange} multiple />
                                        <input type="hidden" className="form-control" id="categoryFileUrl"  />
                                        <input type="hidden" className="form-control" id="categoryId"  />

                                        {/* <button onClick={handleUploadClick}>Upload</button> */}
                                        {/* <button className="btn btn-primary upload_btn_survey" onClick={browseFile}> <i className="fa fa-upload"></i> Upload Questionnaire  </button> */}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <img class="mt-3" id="imageView" style={{display:'none',height:"50px", border:"2px solid grey"}} src ="" />
                                        
                                    <div class="mt-3" style={{display:'inline-block'}}>
                                        <label> &nbsp; </label>
                                        <button type="button" class="btn btn-primary mx-2" onClick={()=>submitCategory()}>Submit</button>
                                    </div>
                                    <div class="mt-3" style={{display:'inline-block'}}>
                                        <label> &nbsp; </label>
                                        <button type="button" className="btn btn-danger mx-2" id="cancelButton" style={{display:'none'}} onClick={()=>setCategoryFields()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <table class="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr class="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">Sr.No.</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Product Count</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                {categories.length > 0 && (
                                                <tbody>
                                                    {categories.map((category,index) => (
                                                        <tr key={category.id} onDragStart={(e) => dragStart(e, index-1)}
                                                        onDragEnter={(e) => dragEnter(e, index-1)}
                                                        onDragEnd={drop}
                                                 draggable>
                                                        <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                        <td><img style={{width:'100px'}} src={s3bucket+category.description.icon} /></td>
                                                        <td>{category.name}</td>
                                                        <td>{category.productCount}</td>
                                                        <td>
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={category.status==1?"checked":""} onClick={()=>updateCategoryStatus(category.id,category.status)} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-sm" onClick={()=>setCategoryFields(category.id,category.name,category.description.icon)}>Edit </button>
                                                            
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                                )}
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <!-- end row--> */}



                            {/* <!-- end row --> */}

                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Category;