import { postAPI } from "../services/ApiService";
import { checkExpiry, expiryTime } from "../Common/ExpiryTimeCheck";

export const masterData = async({moduleName, subModuleName='', pagePerRec=250, page=1, searchText="", parentId="", countryID="", time=0}) =>{
    const token = localStorage.getItem('token');
    let resData;
    try{
    if (token) {
        let checkExpiryTime = checkExpiry(moduleName)
        if (checkExpiryTime === 'Expired') {
            let payloadData = {
                typeSlug: moduleName,
                page: page,
                recPerPage: pagePerRec,
                q: searchText,
                parent: parentId,
                countryID:countryID
            }
            let data = await postAPI('/master-data/list/', payloadData)
            if (data.status === 1) {
                data.expiryTime = expiryTime(time)
                let key = moduleName+(subModuleName ? subModuleName : '')
                localStorage.setItem(key, JSON.stringify(data));
                resData = data;
            }else{
                return data;
            }
        } else {
           let data = JSON.parse(localStorage.getItem(moduleName))
            if (data.status === 1) {
                resData = data;
            }
        }
    }
    return resData;
    }catch (err) {
        console.log(err);
        return [];
      }
}