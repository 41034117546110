import React, { useState, useEffect, useRef } from 'react'
import { masterData } from '../api/master-data';
import { defaultPage, s3bucket } from '../Common/Constant';
import Util from '../services/Utilliteis';
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import Swal from "sweetalert2";

import { putAPI,postAPI, uploadImageAPI,get, deleteAPI } from '../services/ApiService';
import { event } from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';

function Coupon() {
    const [errors, setErrors] = useState({});//for error handling
    const [loadecheck, setLoadCheck] = useState(false);
    const [pageInfo, setPageInfo] = useState(defaultPage);
    const dataFetchedRef = useRef(false);//used for preventing double loading logic
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();//list of coupons
    const [coupon, setCouponDetail] = useState({});//detail of a coupon
    const [startDate, setStartDate] = useState(new Date());//datepicker start date
    const [endDate, setEndDate] = useState(new Date());//datepicker end date
    
    //Master Data holders
    const [countries, setCountries] = useState();
    const [countryId, setCountryId] = useState();    
    const [currencies, setCurrencies] = useState();
    const [currencyId, setCurrencyId] = useState();
    
     
    //end master data holders

    useEffect(() => {
        document.title = "Coupon - GoToSample"   
        //if(dataFetchedRef.current){ // to stop double loading of APIs, skips in first mount
            const getCountries = async() => {
                let res = await masterData({moduleName:'country'})
                if(res.status === 1){
                    setCountries(res.payload)
                }
            };
            getCountries();
            const getCurrencies = async() => {
                let res = await masterData({moduleName:'currency'})
                if(res.status === 1){
                    setCurrencies(res.payload)
                }
            } 
            getCurrencies();

            getList();
    // }
        //  dataFetchedRef.current = true;

    },[]);

    //UI/UX level and form control functions
    const setCouponFields = (details)=>{
        //setVisiblecouponForm({visible:true,title:"Edit Coupon"});
        if(details.startDate?.length>0){
            let startDateSplit = details.startDate.split('-');
            details.startDateStd = new Date(startDateSplit[2]+'-'+startDateSplit[1]+'-'+startDateSplit[0]);
        }
        setStartDate(details.startDateStd);

        if(details.endDate?.length>0){
            let endDateSplit = details.endDate.split('-');
            details.endDateStd = new Date(endDateSplit[2]+'-'+endDateSplit[1]+'-'+endDateSplit[0]);
        } 
        setEndDate(details.endDateStd); 

        setCouponDetail(details);          
    }

    

    
//end UI/UX level functions

//START API level/called functions, considering to move to another file
    const getList = async() =>{
        if(document.getElementById("dataMessage"))
        document.getElementById("dataMessage").innerHTML = "Loading . . .";
        let data = {page: 0,recPerPage: 50, q: searchKeyword}
        let res = await get('/coupon/list', data);        
            if(res.status === 1){
                setLists(res.payload)
                setPageInfo(res.paginationInfo)
            }else{
            setLists([]);
            Swal.fire({
                text:res.message,
                timer: 2000,
            })
        if(document.getElementById("dataMessage"))
            document.getElementById("dataMessage").innerHTML = res.message;
            }
    }

    const handleChange = async (e) => {
        let file = e.target.files;
        var fileElement = file[0];
        var fileExtension = "";
        if (fileElement.name.lastIndexOf(".") > 0) {
            fileExtension = fileElement.name.substring(fileElement.name.lastIndexOf(".") + 1, fileElement.name.length);
        }
        // if (fileExtension.toLowerCase() == "doc" || fileExtension.toLowerCase() == "docx" || fileExtension.toLowerCase() == "pdf" || fileExtension.toLowerCase() == "xls" || fileExtension.toLowerCase() == "xlsx") {
            //setfileerr("");
            let res = await uploadImageAPI('/upload/common/', file)
            console.log('res', res)
            if (res.status === 1) {
                //setSelectedFile(res.url)
                document.getElementById("couponImage").value = res.url;               
            }
    
        // }
        // else {
        //     setfileerr("You must select a doc/xls/pdf file for upload");
        //     return false;
        // }
    }
   
    
    const submitCoupon = async (e) => {
        e.preventDefault();
        setErrors();

        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
        }
        // const formErrors = validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     //setErrors(formErrors)
        // }else{
        let res = {};
            if(!coupon || !coupon.ID)
            res = await postAPI('/coupon/save', formInput)
            else
            res = await putAPI('/coupon/update/'+coupon.ID, formInput)
            if(res.status === 1){
                //setVisiblecouponForm({});
                e.target.reset();
                setCouponDetail({});
                getList();           
                Swal.fire({text:res.message
                ,timer:2500})      
             }
            else{
                setErrors({...errors,['saveCoupon']:res.message})
             }
        //}
    }

    const updateCouponStatus = async (couponId,status) => {
        // e.preventDefault();
        //setErrors();
        status = status==0?1:0;
        let formData = {
            ID:couponId,
            status:status
        };
        // const formErrors = validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     //setErrors(formErrors)
        // }else{
            let res = await putAPI('/coupon/update-status/'+couponId+'/'+status, formData);
            if(res.status === 1){
                getList();
               
             }
            //else{
            //     //setErrors({...errors,['submit']:res.message})
            // }
        //}
    }
    
    const trashCoupon = async (couponId) => {
        // e.preventDefault();
        setErrors();

        Swal.fire({
            title: 'Are you sure?',
            text: "You are deleting a coupon. You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (result) => {
            if (result.isConfirmed) {
                let formData = {
                    ID:couponId,
                };      
                let res = await deleteAPI('/coupon/delete/'+couponId+'/', formData)
                if(res.status === 1){
                    Swal.fire(
                        'Deleted!',
                        'Coupon has been deleted.',
                        'success'
                      )
                    getList();
                }else{
                    Swal.fire(
                        'Not Deleted!',
                        'Coupon could be deleted.',
                        'failure'
                      )
                }              
            }
          })        
    }  
    
    function decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

// END API called functions

    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Manage Coupon</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                {/* <li className="breadcrumb-item"><a >Dashboards</a></li> */}
                                                <li className="breadcrumb-item active">Manage Coupon</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                          
                           
                            <form  id="couponForm" className='row' onSubmit={submitCoupon}>
                                <div id='point-message' style={{color:"red"}}>{errors?.saveCoupon}</div>                                
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Coupon Name</label>
                                        <input type="text" className="form-control" placeholder="Enter Coupon Name"  name="couponName" id="couponName" defaultValue={coupon?.couponName} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Code</label>
                                        <input type="text" className="form-control" placeholder="Enter Coupon Code"  name="couponCode" id="couponCode" defaultValue={coupon?.couponCode} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Website Name</label>
                                        <input type="text" className="form-control" placeholder="Enter Website Name."  name="websiteName" id="websiteName" defaultValue={coupon?.websiteName} />
                                    </div>
                                </div> 
                                
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Description</label>
                                        <textarea type="text" className="form-control" placeholder="Enter Coupon Description"  name="description" id="description" defaultValue={coupon?.description} ></textarea>
                                    </div>
                                </div>   
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Coupon Image</label>
                                        <input type="file" className="form-control" placeholder="Coupon image" id="couponImageUpload" onChange={handleChange} multiple />
                                        <input type="hidden" className="form-control" name="couponImage" id="couponImage" defaultValue={coupon?.couponImage} />
                                        <img class="" id="imageView" style={{display:(coupon.couponImage?'inline-block':'none'),height:"60px", border:"2px solid grey",'vertical-align':"top"}} src ={coupon?.couponImage} alt="coupon" />
                                    </div>
                                </div>  

                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Redeem Value</label>
                                        <input type="text" className="form-control" placeholder="Enter redeem value"  name="redeemValue" id="redeemValue" defaultValue={coupon?.redeemValue} />
                                    </div>
                                </div>                           
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Start Date</label>
                                        <DatePicker className='form-control' selected={startDate} onChange={(date) => setStartDate(date)} name="startDate" id="startDate" dateFormat="d-MM-yyyy" />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">End Date</label>
                                        <DatePicker className='form-control' selected={endDate} onChange={(date) => setEndDate(date)} name="endDate" id="endDate" dateFormat="d-MM-yyyy"  />
                                    </div>
                                </div>                    
                                
                                <div className="col-lg-3 mt-3" >                                    
                                    <div  style={{display:'inline-block','vertical-align':"top"}}>
                                        <button type="submit" className="btn btn-primary mx-2" >Submit</button>
                                    </div>
                                    <div style={{display:'inline-block','vertical-align':"top"}}>
                                        <label> &nbsp; </label>
                                        <button type="button" className="btn btn-danger mx-2" id="cancelButton"  onClick={()=>{setCouponDetail({});document.getElementById('couponForm').reset()}}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <table className="table table-striped table-hover table_client1" style={{"text-align":"left"}}>
                                                <thead>
                                                    <tr className="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">S.no.</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Name & Code</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Redeem Value</th>
                                                        <th scope="col">Website Name</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Status</th>
                                                    <th scope="col align-item-right" style={{width:"120px"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                {(lists && lists.length > 0 )? (
                                                <tbody>
                                                    {lists?.map((list,i) => (
                                                        <tr key={list?.ID}>
                                                        <td>{i+1}</td>
                                                        <td><img style={{width:'60px'}} src={list.couponImage} /></td>

                                                        <td style={{width:'150px'}}>{list.couponName}<br/><br/><b>Code: {list.couponCode}</b> </td>
                                                        <td style={{width:'150px'}}>Start: {list.startDateFormated}<br/>End: &nbsp; {list.endDateFormated}</td>
                                                        <td>{list.redeemValue}</td>         
                                                        <td>{list.websiteName}</td>
                                                        <td>{list.description}</td>
                                                        <td>
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={list.status==1?"checked":""} onClick={()=>updateCouponStatus(list.ID,list.status)} />
                                                            </div>
                                                        </td>
                                                        <td className='align-item-right'>
                                                            {/* <button className="btn btn-sm btn-success" style={{width:"60px"}} onClick={()=>setCouponFields(list)}><i className='mdi mdi-pencil'></i> Edit </button> */}
                                                                {/* <button className="btn btn-sm btn-danger" style={{width:"70px",'margin-left':"10px"}} onClick={()=>trashCoupon(list.id)}><i className='mdi mdi-delete'></i> delete </button> */}
                                                                <div className="dropdown align-items-right">
                                                                    <div className="dropdown ms-sm-3 header-item topbar-user">
                                                                        <button type="button" className="btn btn-sm btn-success" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{'font-size':"14px"}}>
                                                                        More <i className="mdi mdi-dots-vertical-circle" ></i>
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>{setCouponFields(list);Util.scrollToTop()}} ><i className="mdi mdi-pencil"></i> Edit</a>
                                                                            <div className="dropdown-divider"></div>
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>trashCoupon(list.ID)} ><i className="mdi mdi-delete"></i> Delete</a>                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                                ):(<>
                                                    <tr>
                                                        <td colSpan={9} id="dataMessage" style={{padding:"50px 0"}}>No data found</td>
                                                    </tr>
                                                </>)}
                                            </table>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                            <span aria-hidden="true"> Prev</span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item active"><a className="page-link" href="#">1</a></li>

                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                            <span aria-hidden="true"> Next</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <!-- end row--> */}



                            {/* <!-- end row --> */}

                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />

                       
        </div>

    )
}

export default Coupon;