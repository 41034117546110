import React, { useState, useEffect } from 'react'
import { defaultPage } from '../../Common/Constant';
import { userList, userDeleteRequestApprove, userDeleteRequestReject } from '../../api/users';
import { getDownloadFile } from "../../services/ApiService";
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
// import Swal from 'sweetalert2';
import { CModal, CModalHeader, CModalFooter, CModalTitle, CModalBody } from '@coreui/react';

function DeleteRequestUser() {
    useEffect(() => {
        document.title = "Delete Requested Panelist - GoToSample"
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [listType, setListType] = useState("delete-request");
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState(defaultPage)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [rejectRequestUserID, setRejectRequestUserID] = useState(0);
    const [visibleRejectPopup, setVisibleRejectPopup] = useState(false);

    useEffect(() => {
        getList()
    }, [listType]);

    const changePage = (pageInfo) => {
        setPageInfo(pageInfo)
    }

    const getList = async() =>{
        if(listType){
            setSearchKeyword(document.getElementById('searchKeyWord').value);            
            let payloadData = {
                listType: listType,
                q: searchKeyword ? searchKeyword : "",
                page: pageInfo.current_page ? pageInfo.current_page : 0, 
                recPerPage: pageInfo.perPage ? pageInfo.perPage : 50,
                from: startDate ? Math.floor(new Date(startDate+' 00:00:00').getTime()/1000) : "",
                to: endDate ? Math.floor(new Date(endDate+' 23:59:59').getTime()/1000) : ""
            }
            let res = await userList({payload: payloadData});
            if(res.status === 1){
                setLists(res.payload);
                setPageInfo(res.paginationInfo);
            } else{
                setLists([])
            }
        }
    }

    const downloadUserData = async () => {
        if(listType){
            // set search keyword
            setSearchKeyword(document.getElementById('searchKeyWord').value);
            let payloadData = {
                listType: listType,
                q: searchKeyword ? searchKeyword : "",
                page: pageInfo.current_page ? pageInfo.current_page : 0, 
                recPerPage: pageInfo.perPage ? pageInfo.perPage : 50,
                from: startDate ? Math.floor(new Date(startDate+' 00:00:00').getTime()/1000) : "",
                to: endDate ? Math.floor(new Date(endDate+' 23:59:59').getTime()/1000) : ""
            }
            
            await getDownloadFile("/user/download/?page="+payloadData.page+"&recPerPage="+payloadData.recPerPage+"&q="+payloadData.q+"&from="+payloadData.from+"&to="+payloadData.to+"&listType="+payloadData.listType, "users_"+Math.floor(new Date().getTime() / 1000));
        }
    };

    const acceptDeleteRequest = async (id) => {
        let res = await userDeleteRequestApprove({ payload: {userID: id} });
        if (res !== undefined && res.status === 1) {
            getList()
        }
    };
    const resetVisibleRejectPopup = () => {
        setVisibleRejectPopup(false);
        setRejectRequestUserID(0);
    };
    const rejectDeleteRequest = async (id) => {
        setVisibleRejectPopup(true);
        setRejectRequestUserID(id);
    };
    const cancelRejectPopup = () => {
        resetVisibleRejectPopup();
    };
    const submitRejectPopup = async () => {
        let reason = document.getElementById('rejectReason').value;

        if(rejectRequestUserID > 0 && reason !== ""){
            let res = await userDeleteRequestReject({ payload: {userID: rejectRequestUserID, reason: reason} });
            if (res !== undefined && res.status === 1) {
                getList()
            }
            resetVisibleRejectPopup();
        } else {
            alert("Please enter reason for rejection");
        }
    };

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">Panelist</li>
                                                <li className="breadcrumb-item active">Delete Request</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className='d-flex justify-content-end gap-3'>                                
                                <div className="mb-3 row mx-2">
                                    <label htmlFor="Projectdescription" className="form-label mb-0">Search <span className="required_field">*</span></label>
                                    <input type="text" onKeyDown={(e)=>setSearchKeyword(e.target.value)} className="form-control" placeholder='Search by name, email, phone . . .' id="searchKeyWord" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="startDate" className="form-label mb-0">From <span className="required_field">*</span></label>
                                    <input type="date" onChange={(e)=>setStartDate(e.target.value)} className="form-control" id="startDate" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="endDate" className="form-label mb-0">To <span className="required_field">*</span></label>
                                    <input type="date" onChange={(e)=>setEndDate(e.target.value)} className="form-control" id="endDate" />
                                </div> 
                                <div className="mb-3 d-flex align-items-center">
                                    <button className="btn btn-primary mt-3" onClick={()=>getList()}>
                                        <i className="mdi mdi-magnify"></i> Go
                                    </button>
                                </div>
                                <div className="mb-3">
                                    <button className="btn btn-primary mt-3" onClick={()=>downloadUserData()}>
                                        <i className="mdi mdi-download"></i> Download
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Name</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Phone</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Email</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Country</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Status</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Type</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Requested On</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-body">
                                                    {lists ? (<>
                                                        {lists?.length > 0 ? (<>
                                                            {lists?.map((row, index) => {
                                                                return(<>
                                                                <tr key={index+1} id={row?.ID}>
                                                                    <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                                    <td style={{textAlign:"left"}}>
                                                                        {row?.firstName !== null ? row?.firstName : "-"} {row?.lastName !== null ? row?.lastName : ""}
                                                                    </td>
                                                                    <td style={{textAlign:"left"}}>{row?.phone && row?.phone !== null && row?.phone !== "" ? row?.phone : "-"}</td>
                                                                    <td style={{textAlign:"left"}}>{row?.email}</td>
                                                                    <td style={{textAlign:"left"}}>{row?.countryTxt && row?.countryTxt !== null && row?.countryTxt !== "" ? row?.countryTxt : "-"}</td>

                                                                    <td style={{textAlign:"left"}}>
                                                                        <span className={row?.statusSlug === "verified" ? "" : row?.statusSlug === "blocked" ? "" : ""}>
                                                                            {row?.statusTxt}
                                                                        </span>
                                                                    </td>
                                                                    <td style={{textAlign:"left"}}>
                                                                        <span className={row?.userType === "b2b" ? "" : ""}>
                                                                            {row?.userType.toUpperCase()}
                                                                        </span>
                                                                    </td>
                                                                    <td style={{textAlign:"left"}}>{row?.deleteRequestInitiatedOn !== null ? row?.deleteRequestInitiatedOn : "-"}</td>
                                                                    <td style={{textAlign:"left"}}>
                                                                        <button className="btn btn-md btn-danger" style={{
                                                                            marginRight: "10px"
                                                                        }} onClick={()=>acceptDeleteRequest(row?.ID)}>
                                                                            <i className="mdi mdi-delete"></i> Accept
                                                                        </button>

                                                                        <button className="btn btn-md btn-success" onClick={()=>rejectDeleteRequest(row?.ID)}>
                                                                            <i className="mdi mdi-close"></i> Reject
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                </>)
                                                            })}
                                                        </>) : (<>
                                                            <tr>
                                                                <td colSpan={9}>No data found</td>
                                                            </tr>
                                                        </>)}
                                                    </>) : 
                                                    (<></>)}                                                  
                                                </tbody>                                                
                                            </table>
                                            <Pagination pagination={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end row--> */}
                            {/* <!-- end row --> */}
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />
            {visibleRejectPopup ? (<>
                <>
                    <CModal visible={visibleRejectPopup} backdrop={"static"} size={"sm"} onClose={() => resetVisibleRejectPopup()}>
                        <CModalHeader style={{ backgroundColor: "#0ab39c", paddingBottom: "10px", margin: "-1px" }} closeButton={false}>
                            <CModalTitle style={{ color: "#fff", width: "100%" }}>
                                Reject delete request
                                <span style={{ float: "right", cursor: "pointer" }} onClick={() => resetVisibleRejectPopup()}> X </span>
                            </CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div className="row d-flex align-items-center">
                                        <h5 className='mb-3'>Are you sure you want to reject delete request?</h5>
                                        <p>Once you reject delete request, user will be able to enjoy all benefits of the platform like earning points by participating in surveys, polls etc. Hence enter valid reason for rejection.</p>

                                        <label htmlFor="rejectReason">Reason: <span className="required_field">*</span></label>
                                        <textarea className="form-control" id="rejectReason" rows="3" required placeholder="Enter reason for rejection"></textarea>
                                        <small style={{marginTop: "5px",color: "#ff0000"}}>Note: Reason is required and it will be sent to user via email.</small>
                                    </div>
                                </div>
                            </div>
                        </CModalBody>
                        <CModalFooter>
                            <button className="btn btn-primary" onClick={() => cancelRejectPopup()} style={{ marginRight: "15px" }}>Cancel</button>
                            <button className="btn btn-success" onClick={() => submitRejectPopup()}>Submit</button>
                        </CModalFooter>
                    </CModal>
                </>
            </>) : (<></>)}
        </div>
    )
}

export default DeleteRequestUser;