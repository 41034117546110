import React from "react"

function Pagination(props) {
    const changePage = (p) =>{
        props.changePage(p)
    }
    let pagination = props.pageInfo;
    return (
        <>
        {pagination?.totalPages > 1 ? (<>
            <nav aria-label="Page navigation example">

                <div style={{"padding-top":"10px","position":"absolute"}}>Showing {(pagination.current_page-1)*pagination.perPage +1} to {pagination.current_page*pagination.perPage>pagination.total?pagination.total:pagination.current_page*pagination.perPage} of {pagination.total} records
                </div>

                <ul className="pagination justify-content-end" style={{cursor:"pointer"}}>
                    <li className="page-item" style={{cursor:"pointer"}}>
                    <a className={(pagination?.previousPage === null) ?"page-link disabled":("page-link")} aria-label="Next" disabled={(pagination?.previousPage === null)?"disabled":""}  onClick={() => changePage(pagination?.previousPage)}>
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                    </a>
                </li>
                {(() => {
                    let pageHtml = [];
                    for (let i = 1; i <= pagination?.totalPages; i++) {
                        pageHtml.push(<li class={pagination?.current_page === i ? "page-item active" : "page-item"}><a class="page-link" onClick={() => changePage(i)}>{i}</a></li>)
                    }
                    return pageHtml
                })()}
                
                <li class="page-item" style={{cursor:"pointer"}}>
                <a class={(pagination?.nextPage === null) ?"page-link disabled":("page-link")} aria-label="Next" disabled={(pagination?.nextPage === null)?"disabled":""} onClick={() => changePage(pagination?.nextPage)}>
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </nav>
        </>) : (<></>)}
        </>
    )

}
export default Pagination;