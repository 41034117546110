import React, { useState, useEffect } from 'react'
import { userList, userProfile } from '../../api/users';
import Swal from "sweetalert2";
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import { get, postAPI, putAPI, uploadImageAPI, getDownloadFile } from "../../services/ApiService";

// import Swal from 'sweetalert2';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';

function ActiveUser() {
    useEffect(() => {
        document.title = "Active Panelist - GoToSample"
    },);
    const [errors, setErrors] = useState({});//for error handling
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageInfo, setPagination] = useState({})
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [user, setUser] = useState({});


    const getRoles = async(page=1) => {
        let data = {page: pageInfo.current_page,recPerPage: 500, q: "",func:''}
        let res = await get('/urm/role/list', data);        
            if(res.status === 1){
                setRoles(res.payload);
            }
      }

      useEffect(() => {
        getRoles()
    }, []);

        
      const getList = async() =>{
            setSearchKeyword(document.getElementById('searchKeyWord').value); 
            let payloadData = {
                q: searchKeyword ? searchKeyword : "",
                page: pageInfo.current_page ? pageInfo.current_page : 1, 
                recPerPage: 50,
            //     from: startDate ? Math.floor(new Date(startDate+' 00:00:00').getTime()/1000) : "",
            //     to: endDate ? Math.floor(new Date(endDate+' 23:59:59').getTime()/1000) : ""
            }
            let res = await get('/urm/user/list/',payloadData);
            if(res.status === 1){
                setUsers(res.payload);
                setPagination(res.paginationInfo);
            } else{
                setUsers([])
            }
        
    }

    useEffect(() => {
        getList()
    }, []);


    const changePage = (p) => {
        pageInfo.current_page = p;
        getList();
    }

    const submitUser = async (e) => {
        e.preventDefault();
        setErrors();

        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
        }
        // const formErrors = validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     //setErrors(formErrors)
        // }else{
        let res = {};
            if(!user || !user.ID)
            res = await postAPI('/urm/user/save', formInput)
            else
            res = await putAPI('/urm/user/update/'+user.ID, formInput)
            if(res.status === 1){                
                setUser({});
                getList();           
                Swal.fire({text:res.message
                ,timer:2500})      
             }
            else{
                setErrors({...errors,['saveUser']:res.message})
             }
        //}
    }

    const downloadUserData = async () => {
            // set search keyword
            setSearchKeyword(document.getElementById('searchKeyWord').value);
            let payloadData = {
                q: searchKeyword ? searchKeyword : "",
                page: pageInfo.current_page ? pageInfo.current_page : 0, 
                recPerPage: pageInfo.recPerPage ? pageInfo.recPerPage : 50,
                from: startDate ? Math.floor(new Date(startDate+' 00:00:00').getTime()/1000) : "",
                to: endDate ? Math.floor(new Date(endDate+' 23:59:59').getTime()/1000) : ""
            }
            
            await getDownloadFile("/user/download/?page="+payloadData.page+"&recPerPage="+payloadData.recPerPage+"&q="+payloadData.q+"&from="+payloadData.from+"&to="+payloadData.to, "users_"+Math.floor(new Date().getTime() / 1000));
        
    };

    const fetchUserProfile = async (id) => {
        let res = await userProfile({ payload: {userId: id} });
        if (res.status === 1) {
            setUser(res.payload);
        }
    }


    const handleChange = async (e) => {
        let file = e.target.files;
        var fileElement = file[0];
        var fileExtension = "";
        if (fileElement.name.lastIndexOf(".") > 0) {
            fileExtension = fileElement.name.substring(fileElement.name.lastIndexOf(".") + 1, fileElement.name.length);
        }
        // if (fileExtension.toLowerCase() == "doc" || fileExtension.toLowerCase() == "docx" || fileExtension.toLowerCase() == "pdf" || fileExtension.toLowerCase() == "xls" || fileExtension.toLowerCase() == "xlsx") {
            //setfileerr("");
            let res = await uploadImageAPI('/upload/common/', file)
            console.log('res', res)
            if (res.status === 1) {
                //setSelectedFile(res.url)
                document.getElementById("profileImg").value = res.url;
               
            }
    
        // }
        // else {
        //     setfileerr("You must select a doc/xls/pdf file for upload");
        //     return false;
        // }
    }
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">URM</li>
                                                <li className="breadcrumb-item active">users</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <form className='row' onSubmit={submitUser}>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">First name </label>
                                        <input type="text" className="form-control" placeholder="Enter user name" id="firstName" name="firstName" defaultValue={user.firstName} required />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Last name </label>
                                        <input type="text" className="form-control" placeholder="Enter user name" id="lastName" name="lastName" defaultValue={user.lastName}  required />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Email </label>
                                        <input type="text" className="form-control" placeholder="Enter role name" id="email" name="email" defaultValue={user.email}  required />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Phone </label>
                                        <input type="text" className="form-control" placeholder="Enter phone number" id="phone" name="phone" defaultValue={user.phone}   />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <input type="hidden" className="form-control" id="roleId"  />

                                        <label className="form-label mb-0">Role </label>
                                        <select type="text" className="form-control" placeholder="Enter role name" id="roleID" name='roleID' required >
                                        <option value={""}>Select role</option>
                                        {
                                            roles.map(role=>{
                                                return (
                                                    <option value={role.id} selected={role.id==user.roleID?true:false}>{role.name}</option>
                                                )
                                            })
                                        }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Avatar (Dimension: 200 X 200 pixels)</label>
                                        <input type="file" className="form-control" placeholder="type role image"  onChange={handleChange} multiple />
                                        <input type="hidden" className="form-control" id="profileImg" name="profileImg" defaultValue={user.profileImg}  />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Password </label>
                                        <input type="password" className="form-control" placeholder="Enter role password" id="password" name="password"    />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <img class="mt-3" id="imageView" style={{display:'none',height:"50px", border:"2px solid grey"}} src ="" />
                                    <div class="mt-3" style={{display:'inline-block'}}>
                                        <label> &nbsp; </label>
                                        <button type="submit" class="btn btn-primary mx-2" >Submit</button>
                                    </div>
                                    <div class="mt-3" style={{display:'inline-block'}}>
                                        <label> &nbsp; </label>
                                        <button type="reset" className="btn btn-danger mx-2" id="cancelButton" onClick={(e)=>setUser({})} style={{display:''}} >Reset</button>
                                    </div>
                                </div>
                            </form>
                            
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                        <div className='d-flex justify-content-end gap-3'>                                
                                        <div className="">
                                        <input type="text" onKeyDown={(e)=>setSearchKeyword(e.target.value)} className="form-control" placeholder='Search by name, email, phone . . .' id="searchKeyWord" />
                                        </div>
                                            {/* <div className="mb-3">
                                                <label htmlFor="startDate" className="form-label mb-0">From <span className="required_field">*</span></label>
                                                <input type="date" onChange={(e)=>setStartDate(e.target.value)} className="form-control" id="startDate" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="endDate" className="form-label mb-0">To <span className="required_field">*</span></label>
                                                <input type="date" onChange={(e)=>setEndDate(e.target.value)} className="form-control" id="endDate" />
                                            </div>  */}
                                            <div className="mb-3 d-flex align-items-center">
                                                <button className="btn btn-primary" onClick={()=>getList()}>
                                                    <i className="mdi mdi-magnify"></i> Go
                                                </button>
                                            </div>
                                            {/* <div className="mb-3">
                                                <button className="btn btn-primary mt-3" onClick={()=>downloadUserData()}>
                                                    <i className="mdi mdi-download"></i> Download
                                                </button>
                                            </div> */}
                                        </div>
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">Sr. no.</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Image</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Name</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Phone</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Email</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Role</th>
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Country</th> */}
                                                        <th scope="col" style={{textAlign:"left"}}>Status</th>
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Type</th> */}
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Created On</th> */}
                                                        <th scope="col" style={{textAlign:"left"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-body">
                                                    {users ? (<>
                                                        {users?.length > 0 ? (<>
                                                            {users?.map((row, index) => {
                                                                return(<>
                                                                <tr key={index+1} id={row?.ID}>
                                                                <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                                    <td style={{textAlign:"left"}}>
                                                                        <img src={row.profileImg} width="60px" />
                                                                    </td>
                                                                    <td style={{textAlign:"left"}}>
                                                                        {row?.firstName !== null ? row?.firstName : "-"} {row?.lastName !== null ? row?.lastName : ""}
                                                                    </td>
                                                                    
                                                                    <td style={{textAlign:"left"}}>{row?.phone && row?.phone !== null && row?.phone !== "" ? row?.phone : "-"}</td>
                                                                    <td style={{textAlign:"left"}}>{row?.email}</td>
                                                                    {/* <td style={{textAlign:"left"}}>{row?.countryTxt && row?.countryTxt !== null && row?.countryTxt !== "" ? row?.countryTxt : "-"}</td> */}


                                                                    <td style={{textAlign:"left"}}>{row?.roleName}</td>
                                                                    <td style={{textAlign:"left"}}>
                                                                        <span className={row?.statusSlug === "verified" ? "" : row?.statusSlug === "blocked" ? "" : ""}>
                                                                            {row?.statusTxt}
                                                                        </span>
                                                                    </td>
                                                                    {/* <td style={{textAlign:"left"}}>
                                                                        <span className={row?.userType === "b2b" ? "" : ""}>
                                                                            {row?.userType.toUpperCase()}
                                                                        </span>
                                                                    </td> */}
                                                                    {/* <td style={{textAlign:"left"}}>{row?.joinedOn}</td> */}
                                                                    <td style={{textAlign:"left"}}>
                                                                        <div className="dropdown align-items-right">
                                                                    <div className="dropdown ms-sm-3 header-item topbar-user">
                                                                        <button type="button" className="btn btn-sm btn-success" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{'font-size':"14px"}}>
                                                                        More <i className="mdi mdi-dots-vertical-circle" ></i>
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>setUser(row)} ><i className="mdi mdi-pencil"></i> Edit</a>
                                                                            <div className="dropdown-divider"></div>
                                                                            {/* <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>trashRole(list.ID)} ><i className="mdi mdi-delete"></i> Delete</a>                                                                             */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    </td>
                                                                </tr>
                                                                </>)
                                                            })}
                                                        </>) : (<>
                                                            <tr>
                                                                <td colSpan={9}>No data found</td>
                                                            </tr>
                                                        </>)}
                                                    </>) : 
                                                    (<></>)}                                                  
                                                </tbody>                                                
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end row--> */}
                            {/* <!-- end row --> */}
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer /> 
           
        </div>
    )
}

export default ActiveUser;