import { get, postAPI } from "../services/ApiService";
import Swal from "sweetalert2";

export const userList = async({payload}) => {
    let res = await get('/user/list/',payload);
    return res;
}

export const userProfile = async({payload}) => {
    let res = await get('/user/profile?userId='+payload?.userId);
    return res;
}

export const userDeleteRequestApprove = async({payload}) => {
    return Swal.fire({
        text: 'Are you sure you want to approve the delete request?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            let res = await postAPI('/user/delete-account/approve/', payload)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const userDeleteRequestReject = async({payload}) => {
    let res = await postAPI('/user/delete-account/reject/', payload);
    return res;
}