import React, { useState, useEffect, useRef } from 'react'
import { masterData } from '../api/master-data';
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import Swal from "sweetalert2";
import { putAPI,postAPI, uploadImageAPI,get, deleteAPI } from '../services/ApiService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from './Layout/Pagination';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';



function Survey() {
    const [errors, setErrors] = useState({});//for error handling
    const [pageInfo, setPagination] = useState({});
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();//list of surveys
    const [survey, setSurveyDetail] = useState({});//detail of a survey
    const [visibleSurveyForm, setVisibleSurveyForm] = useState({});//survey form modal display control
    const [startDate, setStartDate] = useState(new Date());//datepicker start date
    const [endDate, setEndDate] = useState(new Date());//datepicker end date

    const [pointDetail, setPointDetail] = useState();//Survey points details
    const [visibleDetail, setVisibleDetail] = useState(false);//survey form display control
    const [TADetail, setTADetail] = useState();//Target audience details
    const [visibleTADetail, setVisibleTADetail] = useState(false);// Target AUdience form control
    
    //Master Data holders
    const [countries, setCountries] = useState();
    const [currencyCode, setCurrencyCode] = useState();
    const [countryID, setcountryID] = useState();
    
    const [employeesizes, setEmployeeSizes] = useState();
    const [revenuesizes, setRevenueSizes] = useState();
    const [industries, setIndustries] = useState();
    const [departments, setDepartments] = useState();
    const [designations, setDesignations] = useState();

    const [genders, setGenders] = useState();
    const [agegroups, setAgeGroups] = useState(); 
    const [educations, setEducations] = useState(); 
    const [relationships, setRelationships] = useState(); 
    const [incomegroups, setIncomeGroups] = useState(); 
     
    //end master data holders

    useEffect(() => {
        document.title = "Survey - GoToSample"   
        //if(dataFetchedRef.current){ // to stop double loading of APIs, skips in first mount
            const getCountries = async() => {
                let res = await masterData({'moduleName':'country'})
                if(res.status === 1){
                    setCountries(res.payload)
                }
            };
            getCountries();
           
            const getDepartments = async() => {
                let res = await masterData({moduleName:'departments'})
                if(res.status === 1){
                    setDepartments(res.payload)
                }
            }
            const getIndustries = async() => {
                let res = await masterData({moduleName:'industry'})
                if(res.status === 1){
                    setIndustries(res.payload)
                }
            }
            getIndustries();
            const getDesignations = async() => {
                let res = await masterData({moduleName:'titles'})
                if(res.status === 1){
                    setDesignations(res.payload)
                }
            }
            getDesignations();

            const getGenders = async() => {
                let res = await masterData({moduleName:'gender'})
                if(res.status === 1){
                    setGenders(res.payload)
                }
            }
            getGenders();
            const getAgeGroups = async() => {
                let res = await masterData({moduleName:'age-group'})
                if(res.status === 1){
                    setAgeGroups(res.payload)
                }
            }
            getAgeGroups();

            const getRelationships = async() => {
                let res = await masterData({moduleName:'relationship'})
                if(res.status === 1){
                    setRelationships(res.payload)
                }
            }
            getRelationships();
            
            getList();
    // }
        //  dataFetchedRef.current = true;

    },[]);



    useEffect(() => {
        const getIncomeGroups = async() => {
            if(countryID && countryID != ""){
                let res = await masterData({moduleName:'monthly-income', countryID})
                if(res.status === 1){
                    setIncomeGroups(res.payload);
                }else{
                    setIncomeGroups([]);
                }
            }
        }
        getIncomeGroups();

        const getEmployeeSizes = async() => {
            let res = await masterData({moduleName:'employee-size', countryID})
            if(res.status === 1){
                setEmployeeSizes(res.payload)
            }else{
                setEmployeeSizes([])
            }
        }
        getEmployeeSizes();
        const getRevenueSizes = async() => {
            let res = await masterData({moduleName:'company-revenue', countryID})
            if(res.status === 1){
                setRevenueSizes(res.payload)
            }else{
                setRevenueSizes([])
            }
        }
        getRevenueSizes();
        const getEducations = async() => {
            let res = await masterData({moduleName:'education', countryID})
            if(res.status === 1){
                setEducations(res.payload)
            }else{
                setEducations([])
            }
        }
        getEducations();
    },[countryID]);

    //UI/UX level and form control functions
    const setSurveyFields = (details)=>{
        setVisibleSurveyForm({visible:true,title:"Edit Survey"});
        if(details.startDate?.length>0){
            let startDateSplit = details.startDate.split('-');
            details.startDateStd = new Date(startDateSplit[2]+'-'+startDateSplit[1]+'-'+startDateSplit[0]);
        }
        setStartDate(details.startDateStd);

        if(details.endDate?.length>0){
            let endDateSplit = details.endDate.split('-');
            details.endDateStd = new Date(endDateSplit[2]+'-'+endDateSplit[1]+'-'+endDateSplit[0]);
        } 
        setEndDate(details.endDateStd); 

        setSurveyDetail(details);          
    }

    const managePoints = (ID,b2cPoints,b2bPoints,type) => {        
        setErrors({});       
        let details = {};
        details.ID = ID;
        details.b2cPoints = b2cPoints;
        details.b2bPoints = b2bPoints; 
        details.type = type; 
        //console.log(details);
        setPointDetail(details);
        setVisibleDetail(true);
    };

    const manageTA = (ID,b2cTA,b2bTA,type) => {
        setErrors({});

        if(b2cTA != null && b2bTA != null){
            let countryID = (type == 1)?b2cTA.b2cCountryId:b2bTA.b2bCountryId;
            if(countryID && countryID != ""){
                setCountryData(countryID);
            }
        }

        let details = {};
        details.ID = ID;
        details.b2cTA = b2cTA === null?{}:b2cTA;
        details.b2bTA = b2bTA === null?{}:b2bTA;
        details.type = type; 
        //console.log(details);     
        setTADetail(details);
        setVisibleTADetail(true);
    }
//end UI/UX level functions

//START API level/called functions, considering to move to another file
    const getList = async() =>{
        if(document.getElementById("dataMessage"))
        document.getElementById("dataMessage").innerHTML = "Loading . . .";
        let data = {page: pageInfo.current_page ? pageInfo.current_page : 1,recPerPage: 50, q: searchKeyword}
        let res = await get('/survey/list', data);        
            if(res.status === 1){
                setLists(res.payload)
                setPagination(res.paginationInfo)
            }else{
            setLists([]);
            Swal.fire({
                text:res.message,
                timer: 2000,
            })
        if(document.getElementById("dataMessage"))
            document.getElementById("dataMessage").innerHTML = res.message;
            }
    }

    const changePage = (p) => {
        pageInfo.current_page = p;
        getList();
    }

    const handleChange = async (e) => {
        let file = e.target.files;
        var fileElement = file[0];
        var fileExtension = "";
        if (fileElement.name.lastIndexOf(".") > 0) {
            fileExtension = fileElement.name.substring(fileElement.name.lastIndexOf(".") + 1, fileElement.name.length);
        }
        // if (fileExtension.toLowerCase() == "doc" || fileExtension.toLowerCase() == "docx" || fileExtension.toLowerCase() == "pdf" || fileExtension.toLowerCase() == "xls" || fileExtension.toLowerCase() == "xlsx") {
            //setfileerr("");
            let res = await uploadImageAPI('/upload/common/', file)
            //console.log('res', res)
            if (res.status === 1) {
                //setSelectedFile(res.url)
                document.getElementById("surveyImage").value = res.url;               
            }
    
        // }
        // else {
        //     setfileerr("You must select a doc/xls/pdf file for upload");
        //     return false;
        // }
    }
    
    const submitSurvey = async (e) => {
         e.preventDefault();
         setErrors({});

        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
    }
        // const formErrors = validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     //setErrors(formErrors)
        // }else{
        let res = {};
            if(!survey || !survey.ID)
            res = await postAPI('/survey/save', formInput)
            else
            res = await putAPI('/survey/update/'+survey.ID, formInput)
            if(res.status === 1){
                setVisibleSurveyForm({});
                setSurveyDetail({});
                getList();           
                Swal.fire({text:res.message
                ,timer:2500})      
             }
            else{
                setErrors({...errors,['saveSurvey']:res.message})
             }
        //}
    }

    const updateSurveyStatus = async (surveyId,status) => {
        // e.preventDefault();
        //setErrors({});
        status = status==0?1:0;
        let formData = {
            id:surveyId,
            status:status
        };
        // const formErrors = validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     //setErrors(formErrors)
        // }else{
            let res = await putAPI('/survey/update-status/'+surveyId+'/'+status, formData);
            if(res.status === 1){
                getList();
               
             }
            //else{
            //     //setErrors({...errors,['submit']:res.message})
            // }
        //}
    }
    
    const trashSurvey = async (surveyId) => {
        // e.preventDefault();
        setErrors({});

        Swal.fire({
            title: 'Are you sure?',
            text: "You are deleting a survey. You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (result) => {
            if (result.isConfirmed) {
                let formData = {
                    id:surveyId,
                };      
                let res = await deleteAPI('/survey/delete/'+surveyId+'/', formData)
                if(res.status === 1){
                    Swal.fire(
                        'Deleted!',
                        'Survey has been deleted.',
                        'success'
                      )
                    getList();
                }else{
                    Swal.fire(
                        'Not Deleted!',
                        'Survey could be deleted.',
                        'failure'
                      )
                }              
            }
          })        
    }    

    const startSurvey = async (surveyId) => {
        // e.preventDefault();
        setErrors({});

        Swal.fire({
            title: 'Are you sure?',
            text: "You are starting a survey!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Start it!'
          }).then(async (result) => {
            if (result.isConfirmed) {
                let formData = {
                    id:surveyId,
                };      
                let res = await putAPI('/survey/start/'+surveyId+'/', formData)
                if(res.status === 1){
                    Swal.fire(
                        'STARTED!',
                        'Survey has been started.',
                        'success'
                      )
                    getList();
                }else{
                    Swal.fire(
                        '',
                        res.message,
                        'failure'
                      )
                }              
            }
          })        
    }

    const submitSurveyPoints = async(e) => {
        e.preventDefault();
        setErrors({});

        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
        }
        // const formErrors = validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     //setErrors(formErrors)
        // }else{
        let res = await putAPI('/survey/'+pointDetail?.ID+'/manage-points', formInput);

            if(res.status === 1){
                Swal.fire({
                    text:res.message,
                    timer: 10000,
                }).then(()=>{
                    setVisibleDetail(false);
                    getList();
                })
    }
            else{
                setErrors({...errors,['pointManage']:res.message});
            }
        //}
    }

    const submitTargetAudience = async(e) => {
        e.preventDefault();
        setErrors({});

        const formData = new FormData(e.target);        
       
        for (const [key, value] of formData) {
            let required = "";
            let thisEl = document.getElementsByName(key)[0];
            let el = document.getElementsByName("b2bQuota_"+value);
            if(TADetail?.type == 1){
                el = document.getElementsByName("b2cQuota_"+value);
                required = 'b2c_required';
            }else{
                required = 'b2b_required';
            }

            if(required != "" && thisEl.hasAttribute(required) && value == ""){
                setErrors({...errors,['TAManage']:'Enter required field values'});
                thisEl.focus();
                return false;
            }

            if(el.length > 0 && el[0].value == "" ){
                setErrors({...errors,['TAManage']:'Quota field selected targets is/are required'});
                el[0].focus();
                return false;
            }
              
        }  
        // const formErrors = validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     //setErrors(formErrors)
        // }else{
        let res = await putAPI('/survey/'+TADetail?.ID+'/manage-target-audience', formData);
            //console.log(res);
            if(res.status === 1){
                Swal.fire({
                    text:res.message,
                    timer: 10000,
                }).then(()=>{
                setVisibleTADetail(false);
                getList();
                });
             }
            else{
                setErrors({...errors,['TAManage']:res.message})
            }
        //}
    }
// END API called functions

function checkb2bQuota(e){
    let t = e.target
    document.getElementsByName("b2bQuota_"+t.value)[0].value = "0";
    if(!t.checked)
    document.getElementsByName("b2bQuota_"+t.value)[0].value = "";
}
function checkb2cQuota(e){
    let t = e.target
    document.getElementsByName("b2cQuota_"+t.value)[0].value = "0";
    if(!t.checked)
    document.getElementsByName("b2cQuota_"+t.value)[0].value = "";
}

const setCountryData = (countryID)=>{
    let country = countries.filter(c=>{
        return c.id == countryID
    })[0];
    let currency = (JSON.parse(country.description)).currency;
    //console.log(country);
    setcountryID(countryID);
    setCurrencyCode(currency);
}
    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Manage Survey</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                {/* <li className="breadcrumb-item"><a >Dashboards</a></li> */}
                                                <li className="breadcrumb-item active">Manage Survey</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                          
                            <div className="row mt-2">
                                <div className='col-md-9'></div>
                                <div className='col-md-3' style={{'text-align':"right"}}>
                                    <button className='btn btn-md btn-primary' type='button' onClick={
                                        ()=>{setVisibleSurveyForm({visible:true,title:"Add Survey"}); setSurveyDetail({})}}>Add Survey</button>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <table className="table table-striped table-hover table_client" style={{"text-align":"left"}}>
                                                <thead>
                                                    <tr className="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">S.no.</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Validity</th>
                                                        <th scope="col">Audience</th>
                                                        <th scope="col">Completes</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                {(lists && lists.length > 0 )? (
                                                <tbody>
                                                    {lists?.map((list,index) => (
                                                        <tr key={list?.ID}>
                                                        <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                        <td><img style={{width:'100px'}} src={list.surveyImage} /></td>
                                                        <td>{list.surveyTitle}</td>
                                                        <td>{list.surveyType ==1?"B2C":list.surveyType ==2?"B2B":"B2C & B2B"}</td>
                                                        <td>{(list.startDate && list.startDate) ?<>{list.startDateFormated}-{list.endDateFormated}</>:""}</td>
                                                        <td>{list.audience}</td>
                                                        <td></td>
                                                        <td><div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={list.status==1?"checked":""} onClick={()=>updateSurveyStatus(list.ID,list.status)} />
                                                            </div></td>
                                                        <td>
                                                            {/* <button className="btn btn-sm btn-success" style={{width:"60px"}} onClick={()=>setSurveyFields(list)}><i className='mdi mdi-pencil'></i> Edit </button> */}
                                                                {/* <button className="btn btn-sm btn-danger" style={{width:"70px",'margin-left':"10px"}} onClick={()=>trashSurvey(list.ID)}><i className='mdi mdi-delete'></i> delete </button> */}
                                                                <div class="dropdown">
                                                                    <div className="dropdown ms-sm-3 header-item topbar-user">
                                                                        <button type="button" className="btn btn-sm btn-success" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{'font-size':"14px"}}>
                                                                        More <i className="mdi mdi-dots-vertical-circle" ></i>
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>setSurveyFields(list)} ><i className="mdi mdi-pencil"></i> Edit</a>
                                                                            <div className="dropdown-divider"></div>
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>managePoints(list.ID,list.b2cPoints,list.b2bPoints,list.surveyType)} ><i className="mdi mdi-trophy-award"></i> Manage Points</a>
                                                                            <div className="dropdown-divider"></div>
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>manageTA(list.ID,list.b2cTargetAudience,list.b2bTargetAudience,list.surveyType)} ><i className="mdi mdi-account-switch"></i> Manage Target Audience</a>
                                                                            <div className="dropdown-divider"></div>
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>startSurvey(list.ID)} ><i className="mdi mdi-clock-start"></i> Start</a>   
                                                                            <div className="dropdown-divider"></div>
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>trashSurvey(list.ID)} ><i className="mdi mdi-delete"></i> Delete</a>                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                                ):(<>
                                                    <tr>
                                                        <td colSpan={9} id="dataMessage" style={{padding:"50px 0"}}>No data found</td>
                                                    </tr>
                                                </>)}
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <!-- end row--> */}



                            {/* <!-- end row --> */}

                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />

            {visibleSurveyForm && visibleSurveyForm.visible ? (<>
                <>
            <CModal visible={visibleSurveyForm} backdrop={"static"} size={"xl"} onClose={() => setVisibleSurveyForm({})} id='surveyModal'>
                <CModalHeader style={{ backgroundColor: "#405189", paddingBottom: "10px", margin: "-1px" }} closeButton={false} >
                    <CModalTitle style={{ color: "#fff", width: "100%" }}>
                    {visibleSurveyForm?.title}
                        <span style={{ float: "right", cursor: "pointer" }} onClick={() => setVisibleSurveyForm({})} > X </span>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form  id="surveyForm" className='row' onSubmit={submitSurvey}>
                    <div id='point-message' style={{color:"red"}}>{errors?.saveSurvey}</div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label mb-0">Survey Title</label>
                                <input type="text" className="form-control" placeholder="Enter survey name ..."  name="surveyTitle" id="surveyTitle" defaultValue={survey?.surveyTitle} />
                            </div>
                        </div>
                        <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label mb-0">Link </label>
                                <input type="text" className="form-control" placeholder="Enter survey link ..."  name="surveyLink" id="surveyLink" defaultValue={survey?.surveyLink} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label mb-0">Description</label>
                                <textarea type="text" className="form-control" placeholder="Enter description" name="surveyDesc" id="surveyDesc" >{survey?.surveyDesc}</textarea>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label mb-0">Survey Banner</label>
                                <input type="file" className="form-control" placeholder="Survey image" id="surveyImageUpload" onChange={handleChange} multiple />
                                <input type="hidden" className="form-control" name="surveyImage" id="surveyImage" defaultValue={survey?.surveyImage} />
                                <img class="" id="imageView" style={{display:(survey.surveyImage?'inline-block':'none'),height:"60px","max-width":"100%", border:"2px solid grey",'vertical-align':"top"}} src ={survey?.surveyImage} alt="survey" />
                            </div>
                        </div>
                        
                                                       
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label className="form-label mb-0">Start Date</label>
                                {/* <input type="text" className="form-control" placeholder="dd-mm-yyyy" name="startDate" id="startDate" /> */}
                                <DatePicker className='form-control' selected={startDate} onChange={(date) => setStartDate(date)} name="startDate" id="startDate" dateFormat="d-MM-yyyy" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label className="form-label mb-0">End Date</label>
                                {/* <input type="text" className="form-control" placeholder="dd-mm-yyyy" name="endDate" id="endDate" /> */}
                                <DatePicker className='form-control' selected={endDate} onChange={(date) => setEndDate(date)} name="endDate" id="endDate" dateFormat="d-MM-yyyy" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label mb-0">Survey Type</label>
                                <select className="form-select" data-choices data-choices-sorting="true" name="surveyType" id="surveyType" defaultValue={survey?.surveyType} >
                                    <option value={""} selected>Select Survey Type</option>                                            
                                    <option value={"1"} >B2C</option>                                            
                                    <option value={"2"} >B2B</option>                                            
                                </select>
                            </div>
                        </div> 
                        <hr/>
                        
                        <div className="col-lg-12 mt-3" style={{"text-align":"right"}}>                                    
                            <div  style={{display:'inline-block','vertical-align':"top"}}>
                                <button type="submit" className="btn btn-primary mx-2" >Submit</button>
                            </div>
                            <div style={{display:'inline-block','vertical-align':"top"}}>
                                <label> &nbsp; </label>
                                <button type="button" className="btn btn-danger mx-2" id="cancelButton"  onClick={()=>{setSurveyDetail({});setVisibleSurveyForm({});}}>Cancel</button>
                            </div>
                        </div>
                    </form>
                </CModalBody>
            </CModal>
            </>
            </>) : (<></>)}

            {visibleDetail ? (<>
                <>
            <CModal visible={visibleDetail} backdrop={"static"} size={"xl"} onClose={() => setVisibleDetail(false)}>
                        <CModalHeader style={{ backgroundColor: "#405189", paddingBottom: "10px", margin: "-1px" }} closeButton={false} >
                            <CModalTitle style={{ color: "#fff", width: "100%" }}>
                                Manage Points
                                <span style={{ float: "right", cursor: "pointer" }} onClick={() => setVisibleDetail(false)} > X </span>
                            </CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <form className="row mt-2" onSubmit={submitSurveyPoints}> 
                            <div id='point-message' style={{color:"red"}}>{errors?.pointManage}</div>                               
                                <div className="col-xl-12 col-lg-12 col-md-12" style={{display:(pointDetail?.type === 1?'none':'inline-block')}}>
                                    <div  id="" className='row d-flex align-items-center'>
                                    <input type="hidden" name="ID" id="ID" defaultValue={pointDetail?.ID} />
                                    <input type="hidden" name="type" id="point_type" defaultValue={pointDetail?.type} />
                                           
                                        <h3>B2B Points</h3>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Complete</label>
                                                <input type="number" className="form-control" placeholder=""  name="b2bComplete" id="b2bComplete" defaultValue={pointDetail?.b2bPoints?.Complete} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Terminate</label>
                                                <input type="number" className="form-control" placeholder=""  name="b2bTerminate" id="b2bTerminate" defaultValue={pointDetail?.b2bPoints?.Terminate} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Over Quota</label>
                                                <input type="number" className="form-control" placeholder=""  name="b2bOverQuota" id="b2bOverQuota" defaultValue={pointDetail?.b2bPoints?.OverQuota} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Quality Team</label>
                                                <input type="number" className="form-control" placeholder=""  name="b2bQualityTeam" id="b2bQualityTeam" defaultValue={pointDetail?.b2bPoints?.QualityTeam} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Survey Closed</label>
                                                <input type="number" className="form-control" placeholder=""  name="b2bSurveyClosed" id="b2bSurveyClosed" defaultValue={pointDetail?.b2bPoints?.SurveyClosed} />
                                            </div>
                                        </div>                                       
                                    </div> 
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12" id='b2c-form' style={{display:(pointDetail?.type === 2?'none':'inline-block')}}>
                                    <div  id="" className='row d-flex align-items-center' >
                                        <h3>B2C Points</h3>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Complete</label>
                                                <input type="number" className="form-control" placeholder=""  name="b2cComplete" id="b2cComplete" defaultValue={pointDetail?.b2cPoints?.Complete} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Terminate</label>
                                                <input type="number" className="form-control" placeholder=""  name="b2cTerminate" id="b2cTerminate" defaultValue={pointDetail?.b2cPoints?.Terminate} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Over Quota</label>
                                                <input type="number" className="form-control" placeholder=""  name="b2cOverQuota" id="b2cOverQuota" defaultValue={pointDetail?.b2cPoints?.OverQuota} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Quality Team</label>
                                                <input type="number" className="form-control" placeholder=""  name="b2cQualityTeam" id="b2cQualityTeam" defaultValue={pointDetail?.b2cPoints?.QualityTeam} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Survey Closed</label>
                                                <input type="number" className="form-control" placeholder=""  name="b2cSurveyClosed" id="b2cSurveyClosed" defaultValue={pointDetail?.b2cPoints?.SurveyClosed} />
                                            </div>
                                        </div>                                        
                                    </div> 
                                </div>
                                 
                                <div className="col-lg-12 mt-3">                                    
                                    <hr/>
                                    <div className="" style={{display:'inline-block','vertical-align':"top"}}>
                                        <button type="submit" className="btn btn-primary mx-2" >Submit</button>
                                    </div>
                                    {/* <div class="" style={{display:'inline-block','vertical-align':"top"}}>
                                        <label> &nbsp; </label>
                                        <button type="button" className="btn btn-danger mx-2" id="cancelButton" style={{display:'none'}} onClick={()=>setSurveyFields({})}>Cancel</button>
                                    </div> */}
                                </div>
                            </form>
                        </CModalBody>
            </CModal>
            </>
            </>) : (<></>)}

            {visibleTADetail ? (<>
                <>
            <CModal visible={visibleTADetail} backdrop={"static"} size={"xl"} onClose={() => setVisibleTADetail(false)}>
                        <CModalHeader style={{ backgroundColor: "#405189", paddingBottom: "10px", margin: "-1px" }} closeButton={false} >
                            <CModalTitle style={{ color: "#fff", width: "100%" }}>
                                Manage Target Audience - {TADetail?.type === 1?"B2C":"B2B"}
                                <span style={{ float: "right", cursor: "pointer" }} onClick={() => setVisibleTADetail(false)} > X </span>
                            </CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <form className="row mt-2" onSubmit={submitTargetAudience}>
                                <div id='ta-message' style={{color:"red"}}>{errors?.TAManage}</div> 
                                <div className="col-xl-12 col-lg-12 col-md-12" style={{display:(TADetail?.type === 1?'none':'inline-block')}}>
                                    
                                    <div  id="" className='row d-flex align-items-center' >
                                        
                                        <input type="hidden" className="form-control" placeholder=""  name="ID" id="ID" defaultValue={TADetail?.ID} />
                                        <input type="hidden" name="type" id="ta_type" defaultValue={TADetail?.type} />
                                    
                                        {countries && countries.length > 0 && (<>
                                            <h5 className="form-label mb-3" ><span style={{"border-bottom":"3px solid blue"}}>Country</span></h5>                                            
                                            <div className="col-md-2 mt-10">
                                                <div className="mb-3">    
                                                    <select id="countryId" name='b2bCountryId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"   onChange={(e)=>setCountryData(e.target.value)} >
                                                        <option value={""}  selected>Select Country</option>
                                                            {countries?.map((row) => {
                                                                return (<option value={row.id} selected={row.id == TADetail?.b2bTA?.b2bCountryId ? true : false} >{row.name}</option>)
                                                            })}
                                                    </select> 
                                                </div>
                                            </div>
                                            <div className="col-md-2 mt-10">
                                                    <div className="mb-3">
                                                    <input type="number"  name="b2bCountryQuota" placeholder='Enter Quota' className='form-control' defaultValue={TADetail?.b2bTA?.b2bCountryQuota} b2b_required="1" />
                                                </div>
                                            </div>

                                            </>
                                        )}
                                        {employeesizes && employeesizes.length > 0 && (<>
                                        <hr/>
                                            <h5 className="form-label mb-3"><span style={{"border-bottom":"3px solid blue"}}>Employee Size</span></h5>
                                            { employeesizes.map((employeesize)=>(                                                        
                                                <div className="col-md-2">
                                                    <div className="mb-3" style={{"border":"1px solid #ddd","border-radius":"3px","padding":"5px"}}>
                                                    <input type="checkbox" placeholder=""  name="b2bEmployeeSize[]"   defaultValue={employeesize?.id}  defaultChecked = {TADetail?.b2bTA?.b2bEmployeeSize?.includes(employeesize?.id.toString())} onChange={(event)=>checkb2bQuota(event)} />  &nbsp; 
                                                    <span>{employeesize?.name}</span>                                          <br/>
                                                    <input type="number"  name={"b2bQuota_"+employeesize?.id} defaultValue={TADetail?.b2bTA["b2bQuota_"+employeesize?.id]} placeholder='Enter Quota' style={{"width":"99%","height":"30px","padding":"3px","border":"1px solid #ccc"}} />                  
                                                </div>
                                            </div>
                                            ))}
                                            </>
                                        )}
                                        {revenuesizes && revenuesizes.length > 0 && (<>
                                        <hr/>
                                            <h5 className="form-label mb-3"><span style={{"border-bottom":"3px solid blue"}}>Revenue Size</span></h5>
                                            { revenuesizes.map((revenuesize)=>(                                                        
                                                <div className="col-md-2">
                                                    <div className="mb-3" style={{"border":"1px solid #ddd","border-radius":"3px","padding":"5px"}}>
                                                    <input type="checkbox" placeholder=""  name="b2bRevenueSize[]"  defaultValue={revenuesize?.id} defaultChecked = {TADetail?.b2bTA?.b2bRevenueSize?.includes(revenuesize?.id.toString())} onChange={(event)=>checkb2bQuota(event)}   />  &nbsp;
                                                    <span>{revenuesize?.name}</span>                                                            

                                                    <br/>  
                                                    <input type="number"  name={"b2bQuota_"+revenuesize?.id} defaultValue={TADetail?.b2bTA["b2bQuota_"+revenuesize?.id]}  placeholder='Enter Quota' style={{"width":"99%","height":"30px","padding":"3px","border":"1px solid #ccc"}} />                                                              
                                                </div>
                                            </div>
                                            ))}
                                            </>
                                        )}
                                        {industries && industries.length > 0 && (<>
                                        <hr/>
                                            <h5 className="form-label mb-3"><span style={{"border-bottom":"3px solid blue"}}>Industry</span></h5>
                                            { industries.map((industry)=>(                                                        
                                                <div className="col-md-3">
                                                    <div className="mb-3"  style={{"border":"1px solid #ddd","border-radius":"3px","padding":"5px"}}>
                                                    <input type="checkbox" placeholder=""  name="b2bIndustry[]"  defaultValue={industry?.id} defaultChecked = {TADetail?.b2bTA?.b2bIndustry?.includes(industry?.id.toString())} onChange={(event)=>checkb2bQuota(event)}  />  &nbsp; 
                                                    <span>{industry?.name}</span>   <br/>                                           <input type="number"  name={"b2bQuota_"+industry?.id} defaultValue={TADetail?.b2bTA["b2bQuota_"+industry?.id]}  placeholder='Enter Quota' style={{"width":"50%","height":"30px","padding":"3px","border":"1px solid #ccc"}} />                 
                                                </div>
                                            </div>
                                            ))}
                                            </>
                                        )}
                                        {departments && departments.length > 0 && (<>
                                        <hr/>
                                            <h5 className="form-label mb-3"><span style={{"border-bottom":"3px solid blue"}}>Department</span></h5>
                                            { departments.map((department)=>(                                                        
                                                <div className="col-md-3">
                                                    <div className="mb-3"  style={{"border":"1px solid #ddd","border-radius":"3px","padding":"5px"}}>
                                                    <input type="checkbox" placeholder=""  name="b2bDepartment[]"  defaultValue={department?.id} defaultChecked = {TADetail?.b2bTA?.b2bDepartment?.includes(department?.id.toString())} onChange={(event)=>checkb2bQuota(event)}  />  &nbsp; 
                                                    <span>{department?.name}</span>   <br/>                                           <input type="number"  name={"b2bQuota_"+department?.id} defaultValue={TADetail?.b2bTA["b2bQuota_"+department?.id]}  placeholder='Enter Quota' style={{"width":"50%","height":"30px","padding":"3px","border":"1px solid #ccc"}} />                 
                                                </div>
                                            </div>
                                            ))}
                                            </>
                                        )}
                                        {designations && designations.length > 0 && (<>
                                        <hr/>
                                            <h5 className="form-label mb-3"><span style={{"border-bottom":"3px solid blue"}}>Designation</span></h5>
                                            { designations.map((designation)=>(                                                        
                                                <div className="col-md-3">
                                                    <div className="mb-3" style={{"border":"1px solid #ddd","border-radius":"3px","padding":"5px"}}>
                                                    <input type="checkbox" placeholder=""  name="b2bDesignation[]"  defaultValue={designation?.id} defaultChecked = {TADetail?.b2bTA?.b2bDesignation?.includes(designation?.id.toString())} onChange={(event)=>checkb2bQuota(event)} />  &nbsp;
                                                    <span>{designation?.name}</span> <br/>                                           <input type="number"  name={"b2bQuota_"+designation?.id} defaultValue={TADetail?.b2bTA["b2bQuota_"+designation?.id]}     placeholder='Enter Quota' style={{"width":"50%","height":"30px","padding":"3px","border":"1px solid #ccc"}} />                                                                            
                                                </div>
                                            </div>
                                            ))}
                                            </>
                                        )}
                                    </div> 
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12" style={{display:(TADetail?.type === 2?'none':'inline-block')}}>
                                    <div  id="" className='row d-flex align-items-center'>
                                       
                                        {countries && countries.length > 0 && (<>
                                            <h5 className="form-label mb-3" ><span style={{"border-bottom":"3px solid blue"}}>Country</span></h5>                                            
                                            <div className="col-md-2 mt-10">
                                                <div className="mb-3">    
                                                    <select id="countryId" name='b2cCountryId' className="form-select" data-choices data-choices-sorting="true" b2c_required ="1"  onChange={(e)=>setCountryData(e.target.value)} >
                                                        <option value={""}  selected>Select Country</option>
                                                            {countries?.map((row) => {
                                                                return (<option value={row.id} selected={row.id == TADetail?.b2cTA?.b2cCountryId ? true : false} >{row.name}</option>)
                                                            })}
                                                    </select> 
                                                </div>
                                            </div>
                                            <div className="col-md-2 mt-10">
                                                    <div className="mb-3">
                                                    <input type="number"  name="b2cCountryQuota" placeholder='Enter Quota' className='form-control' defaultValue={TADetail?.b2cTA?.b2cCountryQuota} b2c_required="1" />
                                                </div>
                                            </div>

                                            </>
                                        )}
                                        {genders && genders.length > 0 && (<>
                                        <hr/>
                                            <h5 className="form-label mb-3"><span style={{"border-bottom":"3px solid blue"}}>Gender</span></h5>
                                            { genders.map((gender)=>(                                                        
                                                <div className="col-md-2">
                                                    <div className="mb-3">
                                                    <input type="checkbox" placeholder=""  name="b2cGender[]"  defaultValue={gender?.id} defaultChecked = {TADetail?.b2cTA?.b2cGender?.includes(gender?.id.toString())} onChange={(event)=>checkb2cQuota(event)} />  &nbsp;
                                                    <span>{gender?.name}</span>                                                            
                                                    <br/>                                           <input type="number"  name={"b2cQuota_"+gender?.id} defaultValue={TADetail?.b2cTA["b2cQuota_"+gender?.id]}     placeholder='Enter Quota' style={{"width":"100%","height":"30px","padding":"3px","border":"1px solid #ccc"}} />                                                            
                                                </div>
                                            </div>
                                            ))}
                                            </>
                                        )}
                                        {agegroups && agegroups.length > 0 && (<>
                                        <hr/>
                                            <h5 className="form-label mb-3"><span style={{"border-bottom":"3px solid blue"}}>Age Group</span></h5>
                                            { agegroups.map((agegroup)=>(                                                        
                                                <div className="col-md-2">
                                                    <div className="mb-3">
                                                    <input type="checkbox" placeholder=""  name="b2cAgeGroup[]"  defaultValue={agegroup?.id} defaultChecked = {TADetail?.b2cTA?.b2cAgeGroup?.includes(agegroup?.id.toString())} onChange={(event)=>checkb2cQuota(event)} />  &nbsp;
                                                    <span>{agegroup?.name}</span>                                                            
                                                    <br/>                                           <input type="number"  name={"b2cQuota_"+agegroup?.id} defaultValue={TADetail?.b2cTA["b2cQuota_"+agegroup?.id]}     placeholder='Enter Quota' style={{"width":"100%","height":"30px","padding":"3px","border":"1px solid #ccc"}} />                                                           
                                                </div>
                                            </div>
                                            ))}
                                            </>
                                        )}

                                        {educations && educations.length > 0 && (<>
                                        <hr/>
                                            <h5 className="form-label mb-3"><span style={{"border-bottom":"3px solid blue"}}>Education</span></h5>
                                            { educations.map((education)=>(                                                        
                                                <div className="col-md-2">
                                                    <div className="mb-3">
                                                    <input type="checkbox" placeholder=""  name="b2cEducation[]"  defaultValue={education?.id} defaultChecked = {TADetail?.b2cTA?.b2cEducation?.includes(education?.id.toString())} onChange={(event)=>checkb2cQuota(event)} />  &nbsp;
                                                    <span>{education?.name}</span> 
                                                    <br/>                                           <input type="number"  name={"b2cQuota_"+education?.id} defaultValue={TADetail?.b2cTA["b2cQuota_"+education?.id]}     placeholder='Enter Quota' style={{"width":"100%","height":"30px","padding":"3px","border":"1px solid #ccc"}} />                                                           
                                                </div>
                                            </div>
                                            ))}
                                            </>
                                        )} 

                                        {relationships && relationships.length > 0 && (<>
                                        <hr/>
                                            <h5 className="form-label mb-3"><span style={{"border-bottom":"3px solid blue"}}>Relationship</span></h5>
                                            { relationships.map((relationship)=>(                                                        
                                                <div className="col-md-2">
                                                    <div className="mb-3">
                                                    <input type="checkbox" placeholder=""  name="b2cRelationship[]"  defaultValue={relationship?.id} defaultChecked = {TADetail?.b2cTA?.b2cRelationship?.includes(relationship?.id.toString())} onChange={(event)=>checkb2cQuota(event)} />  &nbsp;
                                                    <span>{relationship?.name}</span> 
                                                    <br/>                                           <input type="number"  name={"b2cQuota_"+relationship?.id} defaultValue={TADetail?.b2cTA["b2cQuota_"+relationship?.id]}     placeholder='Enter Quota' style={{"width":"100%","height":"30px","padding":"3px","border":"1px solid #ccc"}} />                                                           
                                                </div>
                                            </div>
                                            ))}
                                            </>
                                        )}

                                        {incomegroups && incomegroups.length > 0 && (<>
                                        <hr/>
                                            <h5 className="form-label mb-3"><span style={{"border-bottom":"3px solid blue"}}>Income Group</span></h5>
                                            { incomegroups.map((incomegroup)=>(                                                        
                                                <div className="col-md-2">
                                                    <div className="mb-3">
                                                    <input type="checkbox" placeholder=""  name="b2cIncomeGroup[]"  defaultValue={incomegroup?.id} defaultChecked = {TADetail?.b2cTA?.b2cIncomeGroup?.includes(incomegroup?.id.toString())} onChange={(event)=>checkb2cQuota(event)} />  &nbsp;
                                                    <span>{incomegroup?.name}</span>                                                            
                                                    <br/>                                           <input type="number"  name={"b2cQuota_"+incomegroup?.id} defaultValue={TADetail?.b2cTA["b2cQuota_"+incomegroup?.id]}     placeholder='Enter Quota' style={{"width":"100%","height":"30px","padding":"3px","border":"1px solid #ccc"}} />                                                           
                                                </div>
                                            </div>
                                            ))}
                                            </>
                                        )}  

                                                                              
                                                                       
                                    </div> 
                                </div>
                                <div className="col-lg-12 mt-3">                                    
                                    <hr/>
                                    <div className="" style={{display:'inline-block','vertical-align':"top"}}>
                                        <button type="submit" className="btn btn-primary mx-2" >Submit</button>
                                    </div>
                                    {/* <div class="" style={{display:'inline-block','vertical-align':"top"}}>
                                        <label> &nbsp; </label>
                                        <button type="button" className="btn btn-danger mx-2" id="cancelButton" style={{display:'none'}} onClick={()=>setSurveyFields({})}>Cancel</button>
                                    </div> */}
                                </div>
                            </form>
                        </CModalBody>
            </CModal>
            </>
            </>) : (<></>)}
        </div>

    )
}

export default Survey;