import React from 'react'

function Footer() {
    return (
        <div id="layout-wrapper">
            <div className="main-content">
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">&copy; GotoSample {new Date().getFullYear()}. All rights reserved. <i className="mdi mdi-heart text-danger"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>


        </div>
    )
}

export default Footer